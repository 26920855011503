<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        }, {
          routeName: 'attendance',
          name: 'Attendance',
        }
      ]"
      slot="breadcrumb"
    />
    <GgPage
      topType="datePicker"
      pageType="1"
      title="Attendance"
      @onDateClosed="onDateClosed"
    >
      <BaseList
        title="Classes"
        :data="tableData"
        @onClickItem="toStudentListPage"
        style="padding: 0px 15%;"
		:type='1'
      >
	  <div slot="empty" style="text-align: center">
	    <div
	      style="
	        width: 213px;
	        text-align: center;
	        border-radius: 50%;
	        
	        line-height: 200px;
			margin: auto;
			margin-bottom: 12px;
			margin-top: 80px;
	      "
	    >
	      <img
	        style="width: 100%"
	        :src="require('@/assets/images/products/noData.svg')"
	      />
	    </div>
	    <p style="font-size: 22px;color:rgba(32,33,36,0.5);font-family: Roboto;">No data for the day.</p>
	  </div>
	  </BaseList>
	
    </GgPage>
  </div>
</template>

<script>
import { Ajax, Utils } from "@/common";
import { mapState } from "vuex";
import moment from 'moment'
export default {
  name: "Attendance",
  components: {},
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  data() {
    return {
      tableData: [],
      query: {
        // size: 20,
        // current: 1,
        date: '',
        staffId: ''
      },
    };
  },
  methods: {
    onDateClosed(val) {
      if(this.$material.locale.dateFormat=='dd/MM/yyyy'){
    	this.query.date = moment(val,['DD/MM/YYYY']).format('YYYY-MM-DD')
      	sessionStorage.setItem('date', moment(val,['DD/MM/YYYY']).format('YYYY-MM-DD'))
      	
      }else{
    	this.query.date = Utils.formatTime(val, '{y}-{m}-{d}')
      	sessionStorage.setItem('date', Utils.formatTime(val, '{y}-{m}-{d}'))
      }
      
      this.getAllData()
      // this.getData()
    },
    onForwardDate(val) {
      // this.getData(val);
      console.log('forward', val)
    },
    onNextDate(val) {
      // this.getData(val);
      console.log('next', val)
    },
    toStudentListPage(row) {
		if(row.classId==null){
			this.$router.push({
			  name: "attendanceStudent",
			  query: { schoolId: sessionStorage.getItem('locationId'), className: 'All' }
			});
			return false
		}
		if(row.classStatus=='closed'){
			sessionStorage.setItem('spec',this.query.date)
		}else{
			sessionStorage.setItem('spec',"")
		}
      this.$router.push({
        name: "attendanceStudent",
        query: { schoolId: sessionStorage.getItem('locationId'),classId: row.classId, className: row.className }
      });
    },
	getAllData(){
		this.query.staffId = this.user.userId
		this.query.locationId = this.user.locationId
		// this.query.date = moment().format('yyyy-MM-DD')
		let params = {
			staffId:this.user.userId,
			locationId:this.user.locationId,
			startDate:moment(this.query.date).utc().format('YYYY-MM-DD HH:mm:ss')
		}
		Ajax.get("/attendance/checkInOut/statisticsAllCheckInOutEnhanceV1", params).then(
		  res => {
		    let item = res.data || []
		    let arrTemp = []
		 
		      arrTemp.push({
		        classId: null,
		        className: 'All',
		        leading: {
		          icon: "iconatt_all",
		          color: "#437AD1",
		          text: "All",
		        },
		        list: [
		          {
		            text: item.total,
		            isMain: true,
		            subText: "Total",
		          },
		          {
		            text: item.absent,
		            subText: "Absent",
		          },
		          {
		            text: item.present,
		            subText: "Present",
		          },
		          {
		            text: item.checkIn,
		            subText: "Check in",
		          },
		          {
		            text: item.checkOut,
		            subText: "Check out",
		          },
		        ],
		        trailing: {
		          text: item.attendance,
		          subText: "Attendance",
		        }
		      })
		   
		    this.tableData = arrTemp
			// console.log(this.tableData)
			this.getData()
		  }
		);
	},
    getData() {
      this.query.staffId = this.user.userId
      this.query.locationId = this.user.locationId
	  this.query.startDate = moment(this.query.date).utc().format('YYYY-MM-DD HH:mm:ss')
      Ajax.get("/attendance/checkInOut/statisticsAllClassEnhanceV1", this.query).then(
        res => {
          let dataList = res.data || []
          let arrTemp = this.tableData
          dataList.forEach(item => {
            arrTemp.push({
              classId: item.classId,
              className: item.className,
              leading: {
                bgColor: item.themeColor,
                text: item.className,
              },
			  classStatus:item.classStatus,
              list: [
                {
                  text: item.total,
                  isMain: true,
                  subText: "Total",
                },
                {
                  text: item.absent,
                  subText: "Absent",
                },
                {
                  text: item.present,
                  subText: "Present",
                },
                {
                  text: item.checkIn,
                  subText: "Check in",
                },
                {
                  text: item.checkOut,
                  subText: "Check out",
                },
              ],
              trailing: {
                text: item.attendance,
                subText: "Attendance",
              }
            })
          })
		  if(arrTemp.length==1){
			  this.tableData = []
		  }else{
			  this.tableData = arrTemp
		  }
          
        }
      );
    },
  },
  created() {
    this.query.staffId = this.user.userId
    // this.query.date = Utils.formatTime(Date.now(), '{y}-{m}-{d}') // 2021-01-10 00:00:00
  	if(sessionStorage.getItem('date')){
  		this.query.date = sessionStorage.getItem('date')
  	}else{
  		this.query.date = moment().format('YYYY-MM-DD')
  	}
  	if(!this.query.date){
  		this.query.date = moment().format('YYYY-MM-DD')
  	}
    this.getAllData();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main-left {
  display: none;
}
/deep/ .ggPage-main-center.centerPadding1 {
  padding: 0!important;
}
/deep/.md-field .md-input, .md-field .md-textarea{
	 font-size: 14px;
 }
 /deep/.md-field label{
	  font-size: 14px;
 }

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb"},[_c('base-breadcrumb',{attrs:{"slot":"breadcrumb","data":[
        {
          routeName: 'locations',
          name: _vm.user.schoolName,
        }, {
          routeName: 'attendance',
          name: 'Attendance',
        }
      ]},slot:"breadcrumb"}),_c('GgPage',{attrs:{"topType":"datePicker","pageType":"1","title":"Attendance"},on:{"onDateClosed":_vm.onDateClosed}},[_c('BaseList',{staticStyle:{"padding":"0px 15%"},attrs:{"title":"Classes","data":_vm.tableData,"type":1},on:{"onClickItem":_vm.toStudentListPage}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","line-height":"200px","margin":"auto","margin-bottom":"12px","margin-top":"80px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px","color":"rgba(32,33,36,0.5)","font-family":"Roboto"}},[_vm._v("No data for the day.")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- commbook -->
<template>
	<div>
		<el-popover placement="bottom" ref="popover" trigger="click" class="temperature-select-view" v-model="visible">
			<div class="temperature-select" v-show="!disabled">
				<ul class="temperature-select-left">
					<li v-for="item in temperatureIntList" :key="item" @click="firstT = item"
						:class="{active: firstT === item}">{{item}}</li>
				</ul>
				<ul class="temperature-select-right">
					<li v-for="item in temperatureDecimalList" :key="item" @click="lastT = item"
						:class="{active: lastT === item}">{{item}}℃</li>
				</ul>
			</div>
			<md-field slot="reference">
	      <label for="movie">Temperature *</label>
	      <md-input v-model="temperatureValue" :disabled="disabled" readonly></md-input>
	  	  <div class="select-icon-arrow active">
	  	  	      <md-icon class="md-icon-image" v-once>
	  	  	        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
	  	  	          <path d="M7 10l5 5 5-5z" />
	  	  	          <path d="M0 0h24v24H0z" fill="none" />
	  	  	        </svg>
	  	  	      </md-icon>
	  	  	      </div>
	    </md-field>
			<div style="text-align: right;" v-show="!disabled">
				<base-button @click="visible = false">Cancel</base-button>
				<base-button type="primary" @click="onSave" :disabled="!firstT || !lastT">Save</base-button>
			</div>

		</el-popover>
		<!-- <div class="Temperature">
			<p :class=" state == 'In class' ? 'blue' : '' " @click="state = 'In class'">In class</p>
			<p :class=" state == 'Ad hoc' ? 'blues' : '' " @click="state = 'Ad hoc'">Ad hoc</p>
			<span v-popover:popover>{{temperatureValue}} {{ temperatureValue ? '°C' : '' }}</span>
		</div> -->
	</div>

</template>

<script>
	export default {
		components: {},
		props: {
			value: {
				type: String,
				default: ''
			},
			dialogVisible: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			value(val) {
				this.temperatureValue = val
			}
		},
		data() {
			return {
				state: 'In class',
				visible: false,
				temperatureValue: "",
				firstT: '',
				lastT: '',
				temperatureIntList: ['34', '35', '36', '37', '38', '39', '40', '41'],
				temperatureDecimalList: ['.0', '.1', '.2', '.3', '.4', '.5', '.6', '.7', '.8', '.9']
			};
		},
		methods: {
			onSave() {
				this.visible = false
				let temperatureValue = this.firstT + this.lastT
				this.temperatureValue = temperatureValue
				this.$emit('save', temperatureValue)
			}
		},
		created() {},
		mounted() {
			this.temperatureValue = this.value
		}
	};
</script>
<style lang='less' scoped>
	//@import url(); 引入公共css类
	.Temperature {
		width: 300px;
		border: 1px solid #1a73e8;
		border-radius: 4px;
		display: flex;
		height: 30px;
		box-sizing: border-box;
		align-items: center;

		p {
			width: 60px;
			height: 100%;
			display: flex;
			border-right: 1px solid #e0e0e0;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.blue {
			background-color: #1a73e8;
			color: #FFFFFF;
			border-radius: 4px 0 0 4px;
		}

		.blues {
			background-color: #1a73e8;
			color: #FFFFFF;
			border-radius: 0 4px 4px 0;
		}
		span {
			height: 100%;
			flex: 1;
			padding-left: 10px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
		}
	}

	.temperature-select {
		width: 260px;
		padding: 16px 8px;

		ul {
			display: inline-block;
			width: 50%;
		}

		li {
			margin-bottom: 5px;
			text-align: center;
			border: 1px solid #e0e0e0;
			border-radius: 4px;
			cursor: pointer;

			&.active {
				background-color: #1A73E8;
				color: #fff;
			}
		}

		&-left {
			li {
				width: 112px;
				height: 32px;
				line-height: 32px;
			}
		}

		&-right {
			li {
				width: 108px;
				height: 24px;
				line-height: 24px;
			}
		}
	}

	.select-btn {
		color: #5f6368;
		font-family: OpenSans-Bold;
		font-size: 13px;
		border-radius: 4px;
		height: 32px;
		line-height: 32px;
		padding: 0 12px;
		background: transparent;
		border: none;
	}
</style>

<!-- commbook -->
<template>
  <gg-Dialog
    :visible.sync="isShow"
	@close='CloseDialog'
    @open="onOpenDialog"
    :before-close="
      () => {
        $emit('cancel');
      }
    "
  >
    <div slot="title">
      {{isEdit ? (isEditModel ? "Edit check" : "Check")  : 'Check' }} in for
      <head-img class="table-cell-img" :src="photo"
	 :firstName="firstName"
	 :lastName="lastName"
	   
	   
	   line-height="20" />
      {{ studentName }}
      <span style="float: right" v-if="!isEditModel">
        <!-- <i class="iconfont iconedit" style="margin-right: 10px;"></i>
        <i class="iconfont iconyiquxiao" style="margin-right: 10px;" @click="$emit('delete', chooseCheckInOutItem.id)"></i>
        <i class="iconfont iconclear-px" @click="$emit('cancel')"></i> -->
  <!--      <i
          class="el-icon el-icon-edit"
          v-if="
            $buttonPermission('Attendance', 'Full') ||
            $buttonPermission('Attendance', 'Edit')
          "
          @click="isEditModel = true"
        ></i> -->
		<i class="iconfont iconedit"  v-if="
		($buttonPermission('Attendance', 'Full') ||
            $buttonPermission('Attendance', 'Edit'))&&
			currentDate==nowTime
          "
          @click="isEditModel = true"></i>
        <i
          class="iconfont icondelete"
          v-if="$buttonPermission('Attendance', 'Full')"
          @click="$emit('delete', chooseCheckInOutItem.id)"
        ></i>
        <i class="iconfont iconclose" @click="$emit('cancel')"></i>
      </span>
    </div>
    <div class="dialog-content">
		<div class="dialog-content-Top"  v-if="isEditModel">
			<div class="floats">
				<p style="padding-bottom: 10px; color: #B4B4B4;">Date</p>
				<span style="font-weight: 600;color: #000;">{{dateS}}</span>
			</div>
			<div class="floats">
				<p style="padding-bottom: 10px; color: #B4B4B4;" v-if="timeStateShow">Time</p>
				<p style="padding-bottom: 10px; color: #ea4335;" v-else>Time</p>
				<!-- 错误提示 -->
				<div class="errorText" v-if="!timeStateShow">Invalid time</div>
				<!-- <span>{{ DateNow }}</span> -->
				<div class="time-Item">
					<div class="left">
						
						<el-input :maxlength='2' @input="inputHH" v-model="timeData.hh" placeholder=""></el-input>
						<span style="margin: 0 10px;font-size: 16px;color:#202124;font-family: 'Roboto';">:</span>
						<el-input :maxlength='2' @input="inputmm" v-model="timeData.mm" placeholder=""></el-input>
						
					</div>
					<div class="right">
						<span :class=" timeData.state == 'AM' ? 'blue' : '' " @click="stateBtnS('AM')">AM</span>
						<span :class=" timeData.state == 'PM' ? 'blues' : '' "  @click="stateBtnS('PM')">PM</span>
					</div>
				</div>
			</div>
		</div>
		<div class="dialog-content-Tops" v-else>
			<div class="floatss">
				<p style="padding-bottom: 10px; color: #B4B4B4;">Date</p>
				<span style="font-weight: 400;color: #606266;">{{dateS}}</span>
			</div>
			<div class="floatss">
				<p style="padding-bottom: 10px; color: #B4B4B4;">Time</p>
				<!-- <span>{{ DateNow }}</span> -->
				<span style="font-weight: 400;color: #606266;">{{chooseCheckInOutItem.createTime | editTime}}</span>
			</div>
		</div>
      <baseGGSelect
        v-if="isEditModel"
        v-model="paramsDetail.dropOffUser"
        placeholder="Drop-off by * "
        class="float"
      >
        <md-option
          v-for="(item, key) in dropOffUserList"
          :key="key"
          :value="item.id"
		  
        >
          {{ item.firstName + " " +item.lastName  }}
        </md-option>
      </baseGGSelect>
      <div class="float" v-else>
        <p style="padding-bottom: 10px; color: #b4b4b4">Drop-off by</p>
	<!-- 	
        <gg-chip
		  type='input'
        >
		<div class="user-select-chip">
		  <head-img
		    class="user-avatar"
		    :firstName="dropOffUserName.split(' ')[0]"
		    :lastName="dropOffUserName.split(' ')[1]"
		    line-height="36"
		  />
		  <span>{{ dropOffUserName  }}</span>
		</div>
        </gg-chip>
		 -->
        <p style="min-width: 70px; text-align: center; float: left; margin-right: 8px; padding: 6px 8px; border-radius: 20px; border: 1px solid #E0E0E0;" v-if="dropOffUserName">{{ dropOffUserName }}</p>
		<p v-else>-</p>
	  </div>
      <temperatureSelect
        v-if="isEditModel"
        class="float"
        @save="onSaveTemp"
        :value="paramsDetail.checkValue"
      />
      <div class="float" v-else style="width: calc(50%);flex-basis: calc(50%);">
        <p style="padding-bottom: 10px; color: #b4b4b4">Temperature</p>
        <span style="display: block;padding: 6px 8px;">{{ paramsDetail.checkValue }}°C</span>
      </div>
<!-- 	  <gg-chips
	    v-model="paramsDetail.symptomsList"
	
	    ref="selectChips"
	    md-check-duplicated
	  >
	   
	  </gg-chips> -->
      <baseGGSelect
        v-if="isEditModel"
        v-model="paramsDetail.symptomsList"
        placeholder="Health check"
        multiple
		:chips='true'
      >
        <md-optgroup label="Illness" v-if="illnessHealthCheckList.length">
          <md-option :value="item.value" v-for="(item, key) in illnessHealthCheckList" :key="key">{{ item.value}}</md-option>
        </md-optgroup>
        <md-optgroup label="Visual Health Check" v-if="visualHealthCheckList.length">
          <md-option :value="item.value" v-for="(item, key) in visualHealthCheckList" :key="key">{{ item.value }}</md-option>
        </md-optgroup>
      </baseGGSelect>
      <div v-else style="width: 100%; padding-top: 20px">
        <p style="padding-bottom: 10px; color: #b4b4b4">Health check</p>
        <p style="min-width: 70px; text-align: center; float: left; margin-right: 8px; padding: 6px 8px; border-radius: 20px; border: 1px solid #E0E0E0;" 
          v-for="(item, key) in paramsDetail.symptomsList"
          :key="key"
		  v-if="paramsDetail.symptomsList && paramsDetail.symptomsList.length!=0"
        >
          {{ item }}
        </p>
		<p v-if='paramsDetail.symptomsList.length==0'>-</p>
		<!-- <p >-</p> -->
        <!-- <span>{{
          paramsDetail.symptomsList && paramsDetail.symptomsList.join(",")
        }}</span> -->
      </div>
      <gg-input
        v-if="isEditModel"
        v-model="paramsDetail.remarks"
        placeholder="Remarks"
      />
      <div v-else style="width: 100%; padding-top: 20px">
        <p style="padding-bottom: 10px; color: #b4b4b4">Remarks</p>
        <span>{{ paramsDetail.remarks|| '-' }}</span>
      </div>
      <div style="width: 100%">
		  <el-upload
		    style="display: inline-block;"
		    :auto-upload="false"
		    action=""
		    accept="image/jpeg,image/png"
		    :show-file-list="false"
			:disabled="fileList && fileList.length==1"
		    :on-change="(file, fileList) => {onUploadFileChange(file, fileList)}"
		  >
		  		<el-button
		  		  v-if="isEditModel"
		  		  size="small"
		  		  type="primary"
		  		  class="addImage"
				  
				  :disabled="fileList && fileList.length==1"
		  		  >
		  		  <span class="icon iconfont iconattach_file-web"></span>
		  		  
		  		  Add image *</el-button
		  		>
		  </el-upload>
       
        <div style="padding-top: 20px">
          <imageListPreview :fileList="fileList" :showClose='isEditModel'/>
        </div>
      </div>
      <p class="text-reminder" v-if="isEditModel">* indicates a required field</p>
    </div>
    <span slot="footer" class="dialog-footer" v-if="isEditModel">
      <gg-cancel-button @click="$emit('cancel')">Cancel</gg-cancel-button>
      <gg-submit-button
        @click="onSave"
        :disabled="
          !isEditModel ||
          !fileList.length ||
          !paramsDetail.dropOffUser ||
          !paramsDetail.checkValue  ||
		  (!is_changed&&!is_timeDatas)
		   || !timeStateShow
        "
        >{{ isEditable ? "Save" : "Check in" }}</gg-submit-button
      >
    </span>
    <img-cut-modal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
	  :isCut='true'
    />
  </gg-Dialog>
</template>

<script>
import { Ajax,Utils } from "@/common";
import { mapState } from "vuex";
import temperatureSelect from "../modules/temperature-select.vue";
import imageListPreview from "@/components/private-components/ImageListPreview/index.vue";
import moment from 'moment'
export default {
  components: {
    temperatureSelect,
    imageListPreview,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
	isEdit: {
	  type: Boolean,
	  default: true,
	},
    checkInChoutId: {
      type: String,
      default: "",
    },
    studentId: {
      type: String,
      default: "",
    },
	photo: {
	  type: String,
	  default: "",
	},
    studentName: {
     type: String,
     default: "",
    },
	lastName: {
	 type: String,
	 default: "",
	},
	firstName: {
	 type: String,
	 default: "",
	},
	checkInDate: {
		 type: String,
		 default: "",
		},
    chooseCheckInOutItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
		dateS: moment().format("DD/MM/YYYY"),
		timeData: {
			hh: moment().format("h"),
			mm: moment().format("mm"),
			state: moment().format("A"),
		},
		timeDatas: {
			hh: '',
			mm: '',
			state: '',
		},
      dropOffUserName: "",
      isEditModel: true,
      isImgCutShow: false,
      fileList: [],
      isShow: this.dialogVisible,
      paramsDetail: {
        dropOffUser: "",
        checkValue: "",
        symptomsList: [],
        remarks: "",
        fileList: [],
      },
      dropOffUserList: [],
      illnessHealthCheckList: [],
      visualHealthCheckList: [],
	 firstData: {
	   dropOffUser: "",
	   checkValue: "",
	   symptomsList: [],
	   remarks: "",
	   fileList: [],
	 },
	 is_changed:false,
	 is_timeDatas: false,
	 currentDate:Utils.formatTime(Date.now(), '{y}-{m}-{d}'),
	 nowTime:Utils.formatTime(Date.now(), '{y}-{m}-{d}'),
	 timeStateShow: true
    };
  },
  watch: {
    dialogVisible(val) {
      this.isShow = val;
	  let a 
	  if(sessionStorage.getItem('date')){
	  	a =   Utils.formatTime(sessionStorage.getItem('date'), '{y}-{m}-{d}')
	  }else{
	  	a =  Utils.formatTime(Date.now(), '{y}-{m}-{d}') 
	  }
	  // this.currentDate = a
    },
	paramsDetail:{
		handler(res){
			for(let key in res){
				if(res[key]!=this.firstData[key]){
					this.is_changed=true
				}
			}
			console.log('changed',this.is_changed)
		},deep:true
	},
	timeData:{
		handler(res){
			for(let key in res){
				if(res[key]!=this.timeDatas[key]){
					this.is_timeDatas=true
				}
				console.log('is_timeDatas',this.is_timeDatas)
			}
		},deep:true
	}
  },
  filters: {
  	editTime(e) {
  		if (e) {
  			return moment.utc(e,["YYYY-MM-DD hh:mm:ss"]).local().format("h:mm A")
  		}
  		// return `${e.split(' ')[1]} ${e.split(' ')[2]}`
  	},
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
	  inputHH () {
	  		this.timeData.hh=this.timeData.hh.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')
	  		if (this.timeData.hh > 12 || this.timeData.mm > 60 || this.timeData.hh == '' || this.timeData.mm == '') {
	  			this.timeStateShow = false
	  		} else {
	  			this.timeStateShow = true
	  		}
	  },
	  inputmm () {
	  		  this.timeData.mm=this.timeData.mm.replace(/[^\d]/g,'').replace(/^-1{1,}/g,'')
	  		  if (this.timeData.hh > 12 || this.timeData.mm > 60 || this.timeData.hh == '' || this.timeData.mm == '') {
	  		  	this.timeStateShow = false
	  		  } else {
	  		  	this.timeStateShow = true
	  		  }
	  },
	  stateBtnS(e) {
	  	this.timeData.state = e
	  },
	  onUploadFileChange(file, fileList){
	  	 const isLt2M = file.size / 1024 / 1024 < 25     //这里做文件大小限制
	  			  if(!isLt2M) {
	  			  	this.$message({
	  			  		message: 'Upload files should not exceed 25MB',
	  			  		type: 'warning'
	  			  	});
	  			  	return  isLt2M
	  			  }
	    if (file) {
	      let fileName = file.name
	      let formData = new FormData();
	      formData.append("file", file);
	      formData.append("multipartFile", file.raw);
	      Ajax.post(
	        // "/media/public/file/upload",
	        "/media/public/file/uploadExt",
	        formData,
	        {
	          headers: { "Content-Type": "multipart/form-data" },
	        }
	      )
	      .then((res) => {
	        if (res.code === "0000") {
				this.is_changed=true
	          let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
	          this.fileList.push({
	            fileName,
	            src: imgSrc,
	            id: res.data.requestId,
	          });
	  		
	        }
	      })
	      .catch((err) => {
	        console.log(err)
	      });
	    }
	  },
    onSave() {
		this.dropOffUserList.forEach((item) => {
		  if (this.paramsDetail.dropOffUser === item.id) {
		   this.paramsDetail.dropUserType=item.type
		  }
		});
      let fileList = [];
      this.fileList.forEach((item) => {
        fileList.push(item.src);
      });
      this.paramsDetail.fileList = fileList;
	  this.is_changed=false
      this.$emit("save", {paramsDetail:this.paramsDetail,timeData: this.timeData});
    },
    _uploadCallback(formData, fileData) {
      let fileName = fileData.name;
      formData.append("multipartFile", fileData.raw);
      this.isImgCutShow = false;
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          this.fileList.push({
            fileName,
            src: imgSrc,
            id: res.data.requestId,
          });
          // this.isImgCutShow = false;
        })
        .catch(() => {
          // this.isImgCutShow = false;
        });
    },
    onSaveTemp(value) {
      this.paramsDetail.checkValue = value;
    },
    // queryListContactByStuId(){
    //   Ajax.get(`/usermanage/pickup/selectByStudentId`, {
    //     studentId: this.studentId
    //   }).then(res => {
    //     this.dropOffUserList = res.data || []
    //   })
    // },
    queryListContactByStuId() {
      Ajax.get(
        `/usermanage/student/listContactByStuId/${this.studentId}`,
        {}
      ).then((res) => {
        this.dropOffUserList = res.data || [];
        this.dropOffUserList.forEach((item) => {
          if (this.chooseCheckInOutItem.dropOffOrPickUpId === item.id) {
            this.dropOffUserName = item.firstName + " " + item.lastName;
          }
        });
      });
    },
    queryHealthCheckList() {
      Ajax.post(`/usermanage/setting/getList`, {
        dataLevel: "healthCheck",
        dataType: "illness",
        userId: this.user.userId,
      }).then((res) => {
        this.illnessHealthCheckList = res.data || []
      });
      Ajax.post(`/usermanage/setting/getList`, {
        dataLevel: "healthCheck",
        dataType: "visualCheck",
        userId: this.user.userId,
      }).then((res) => {
        this.visualHealthCheckList = res.data || []
      });
    },
	CloseDialog () {
		// this.timeData = {
		// 	hh: moment().format("h"),
		// 	mm: moment().format("mm"),
		// 	state: moment().format("A")
		// }
	},
    onOpenDialog() {
      this.queryListContactByStuId();
      this.queryHealthCheckList();
	  this.dateS = moment(this.checkInDate,['YYYY-MM-DD']).format('DD/MM/YYYY')
      if (this.isEditable) {
		  console.log(this.chooseCheckInOutItem)
		  const { createTime } = this.chooseCheckInOutItem
		  let time = moment.utc(createTime,["YYYY-MM-DD hh:mm:ss"]).local().format("hh:mm A")
		  this.timeData.hh = time.split(' ')[0].split(':')[0]
		  this.timeData.hh = Number(this.timeData.hh)
		  this.timeData.mm = time.split(' ')[0].split(':')[1]
		  this.timeData.state = time.split(' ')[1]
        this.fileList = [];
        this.isEditModel = false;
		this.paramsDetail.id = this.chooseCheckInOutItem.id
        this.paramsDetail.dropOffUser = this.chooseCheckInOutItem.dropOffOrPickUpId;
        this.paramsDetail.symptomsList =
          this.chooseCheckInOutItem.symptomsList || []; // (this.chooseCheckInOutItem.symptoms && this.chooseCheckInOutItem.symptoms.split(',')) || []
        this.paramsDetail.remarks = this.chooseCheckInOutItem.remarks;
        this.paramsDetail.checkValue = this.chooseCheckInOutItem.temperatrue;
        this.paramsDetail.fileList = this.chooseCheckInOutItem.fileList || [];
        this.paramsDetail.fileList.forEach((item,index) => {
          this.fileList.push({
            src: item,
			fileName:this.chooseCheckInOutItem.fileNameList[index]
          });
        });
		Object.assign(this.firstData,this.paramsDetail)
		Object.assign(this.timeDatas,this.timeData)
		this.is_changed=false
		this.is_timeDatas=false
      } else {
        this.fileList = [];
        this.paramsDetail = {
          dropOffUser: "",
          checkValue: "",
          symptomsList: [],
          remarks: "",
          fileList: [],
        };
      }
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .el-dialog {
	width: 700px !important;
}
.dialog-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  max-height: 540px;
  overflow: auto;
  .dialog-content-Top {
  	display: flex;
  	width: 100%;
  	flex-wrap: wrap;
  	margin-bottom: 20px;
  	justify-content: space-between;
  }
  .dialog-content-Tops {
  	display: flex;
	width: 100%;
  	flex-wrap: wrap;
  	margin-bottom: 20px;
  	justify-content: space-between;
  }
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  /* padding: 20px; */
  /* max-height: 444px; */
  /* overflow: auto; */
  .text-reminder {
    margin-top: 20px;
    font-size: 12px;
    font-family: Roboto;
    color: #b4b4b4;
  }
  .dialog-content-Top {
  	display: flex;
  	width: 100%;
  	flex-wrap: wrap;
  	margin-bottom: 20px;
  	justify-content: space-between;
  }
  .float {
    width: calc(50%);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
  .floats {
  	width: calc(50% - 10px);
  
  	/* width: calc(50% - 10px);
  	flex-basis: calc(50% - 10px);
  	flex-grow: 0;
  	flex-shrink: 0; */
  	.time-Item {
  		display: flex;
  
  		.left {
  			display: flex;
  			.borders {
  				width: 40px;
  				color: #000;
  				font-size: 16px;
  				padding-left: 6px;
  				border-bottom: 1px solid #ebebeb;
  			}
  			/deep/ .el-input {
  				height:30px;
  				width: 90px;
  				input {
  					height: 100%;
  				}
  			}
  		}
  
  		.right {
  			width: 100px;
  			display: flex;
  			margin-left: 20px;
  			border-radius: 4px;
  			overflow: hidden;
  			border: 1px solid #e0e0e0;
  
  			span {
  				width: 50px;
  				height: 30px;
  				display: flex;
  				font-size: 12px;
  				cursor: pointer;
  				align-items: center;
  				justify-content: center;
  			}
  
  			.blue {
  				background-color: #1a73e8;
  				color: #FFFFFF;
  				border-radius: 4px 0 0 4px;
  			}
  
  			.blues {
  				background-color: #1a73e8;
  				color: #FFFFFF;
  				border-radius: 0 4px 4px 0;
  			}
  		}
  	}
  }
  .text-reminder {
    margin-top: 20px;
    font-size: 12px;
    font-family: Roboto;
    color: #b4b4b4;
  }
  .float {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
  .floatss {
	  flex: 1;
  }
}
.el-icon {
  cursor: pointer;
  margin-left: 10px;
}
/deep/.table-cell-img {
  margin-right: 0px;
}
/deep/.el-upload-list.el-upload-list--text {
  display: none;
}
/deep/.md-subheader.md-theme-default {
  margin-bottom: 5px;
  min-height: 25px;
  font-size: 12px;
  color: #000;
  font-weight: bold;
  border-bottom: 1px solid #707070;
}

 /deep/.iconfont{
	 margin-right:5px;
	 vertical-align:-0.1em;
 }
 .addImage{
	 background-color: #1A73E8;
	 
 }
 .is-disabled{
	  background-color: #a0cfff;
  }
 .addImage:hover{
	 background-color: #2B7DE9;
 }
 .is-disabled:hover{
	 background-color: #a0cfff;
 }
 .iconedit,.iconclose,.icondelete{
	 cursor: pointer;
	 color: #5F6368;
 }
 /deep/.md-chip.md-theme-default{
	 background-color: white;
	 border: 1px solid #E0E0E0;
 }
 .user-select-chip {
   display: flex;
   align-items: center;
   .user-avatar {
     width: 24px !important;
     height: 24px !important;
     line-height: 24px !important;
     margin-right: 4px;
   }
 }
 /deep/.textPhoto{
	 display: block;
 }
 /deep/.chips .md-chip.md-deletable.md-clickable .md-button.md-input-action{
	 width: 20px;
	 height: 20px;
	 margin-left: 8px;
 }
/deep/.chips .md-chip.md-deletable.md-clickable{
	 height: 34px;
	 line-height: 34px;
 }
 /deep/.md-chip.md-clickable>.md-ripple{
	 padding-right: 40px;
 }
 /deep/.md-chip .md-button.md-input-action .md-ripple{
	 background-color: #5f6368;
 }
 /deep/.chips .md-chip.md-deletable.md-clickable .md-button.md-input-action .md-button-content .md-icon{
	 height: 15px;
	 line-height: 15px;
	 min-width: 15px;
	
 }
/deep/.chips .md-chip.md-deletable.md-clickable .md-button.md-input-action .md-button-content{
	 height: 15px;
 }
 /deep/.md-chip.md-theme-default.md-deletable:not(.md-disabled):hover .md-input-action .md-icon svg, .md-chip.md-theme-default.md-clickable:not(.md-disabled):hover .md-input-action .md-icon svg{
	 fill: white;
 }
 /deep/.chips .md-chip.md-deletable.md-clickable{
	  background-color: white;
	 	 border: 1px solid #E0E0E0;
 }
 /deep/.md-chip.md-clickable > .md-ripple{
 		font-size: 14px!important;
 		line-height: 32px;
 	}
</style>
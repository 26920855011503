<template>
  <div class="content has_breadcrumb">
	  
	 
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'attendance',
          name: 'Attendance',
        },
        {
          name: $route.query.className,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage
      topType="datePicker"
      pageType="2"
      title="Attendance"
      @changeTopSelect="onChangeTopSelect"
      @onDateClosed="onDateClosed"
    >
	<!-- expost按钮 -->
	 <!-- <BaseButton type="primary" @click='expostTabVisible = true'>Export</BaseButton> -->
	
      <template v-slot:left>
        <!-- <div class="mar-l-b">
          <gg-create-button @click="openDialogAdd(null)" label="Add staff" />
        </div> -->
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <edoovo-table
        height="100%"
        ref="multipleTable"
        :data="usersData.tableData"
        rowKey="id"
        :title="$route.query.className"
        defaultValue="-"
        class="list-tableBox"
        :v-loading="usersData.isLoading"
        @sortChange="_onSortChange"
        :sort="usersData.sort"
      >
        <!-- ===== -->
        <!-- 空状态 -->
        <!-- ===== -->
        <div slot="empty" style="text-align: center">
          <div
            style="
              width: 213px;
              text-align: center;
              border-radius: 50%;
			  margin: auto;
              margin-bottom: 12px;
              line-height: 200px;
            "
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/products/noData.svg')"
            />
          </div>
          <p style="font-size: 22px">{{warning}}</p>
        </div>
        <!-- ======= -->
        <!-- 分页组件 -->
        <!-- ======= -->
        <edoovo-pagination
          slot="pagination"
          @size-change="_pageSizeChange"
          @current-change="_pageChange"
          :current-page="query.current"
          :page-size="query.size"
          :total="usersData.totalNum"
        />
        <!-- ====== -->
        <!-- 筛选组件 -->
        <!-- ====== -->
        <div
          v-if="filterRenderData.length&&usersData.tableData.length"
          class="list-filterBox"
          slot="filter"
		  style="display: flex;justify-content: space-between;width: 100%;"
        >
          <base-filter
            ref="tablefilter"
            @filterCallback="_filterCallback"
            :renderData="filterRenderData"
            :multKeys="multKeys"
          />
		      <div style="cursor: pointer;" v-if="usersData.tableData&&usersData.tableData.length" class="selectPhotoBtn"  @click='expostTabVisible = true'>Export</div>
        </div>
		
		
        <!-- =========== -->
        <!-- 每一项column -->
        <!-- =========== -->
      <!--  <edoovo-table-column v-slot="{ row }" size="M" label="No.">
          {{row.dataIndex+1}}
        </edoovo-table-column> -->
        <edoovo-table-column
          size="L"
          label="Students name"
          prop="studentName"
          sortName="Students name"
          :getTooltipText="(row) => `${row.studentName}`"
          v-slot="{ row }"
        >
          <div class="table-cell-box" @click="toTimelinePage(row)">
			  <div  class="statusImg">
				 <head-img
				   class="table-cell-img"
				   :src="row.photo ? `${row.photo}` : ``"
				   
				   :firstName="`${row.studentFirstName}`"
				   :lastName="`${row.studentLastName}`"
				   line-height="32"
				   
				 /> 
			  </div>
           
            <span class="table-cell-name">{{ row.studentName }}</span>
          </div>
        </edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Attendance"
          prop="checkIn"
          v-slot="{ row }"
		  align='center'
        >
         <span v-if="row.attendance === 0">-</span>
          <i
            class="el-icon-circle-check"
            style="font-size: 24px; color: #3db175"
            v-if="row.attendance === 1"
			
          ></i>
          <i
            class="el-icon-circle-close"
            style="font-size: 24px; color: #ff7d72"
            v-if="row.attendance === 2"
          ></i>
        </edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Check in"
          prop="checkIn"
          v-slot="{ row }"
          :getTooltipText="(row) => `${row.checkinTime?row.checkinTime:''}`"
        >
          {{ row.checkinTime || "-" }}
        </edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Temperature"
          prop="checkinTemperature"
          v-slot="{ row }"
          :getTooltipText="(row) => `${row.checkinTemperature?row.checkinTemperature:'-'}`"
        >
          {{ row.checkinTemperature ? row.checkinTemperature+'°C': "-" }}
        </edoovo-table-column>
		<edoovo-table-column
		  size="M"
		  label="In class"
		  prop="checkinTemperature"
		  v-slot="{ row }"
		  :getTooltipText="(row) => `${row.inClassTime?row.inClassTime:'-'}`"
		>
		  {{ row.inClassTime ? row.inClassTime: "-" }}
		</edoovo-table-column>
		<edoovo-table-column
		  size="M"
		  label="Temperature"
		  prop="checkinTemperature"
		  v-slot="{ row }"
		  :getTooltipText="(row) => `${row.inClassTemperature?row.inClassTemperature:'-'}`"
		>
		  {{ row.inClassTemperature ? row.inClassTemperature+'°C': "-" }}
		</edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Check out"
          prop="checkOut"
          v-slot="{ row }"
          :getTooltipText="(row) => `${row.checkoutTime}`"
        >
          {{ row.checkoutTime || "-" }}
        </edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Temperature"
          prop="checkoutTemperature"
          v-slot="{ row }"
          :getTooltipText="(row) => `${row.checkoutTemperature}`"
        >
          <!-- {{ row.checkoutTemperature+'°C' || "-" }} -->
		  {{ row.checkoutTemperature? row.checkoutTemperature+'°C': "-" }}
        </edoovo-table-column>
     <edoovo-table-column
       size="M"
       label="Health check"
       prop="type"
       v-slot="{ row }"
	   align='center'
     		:tooltip='false'
     >
       <span v-if="row.healthCheckTime==0">-</span>
       
     		<el-tooltip popper-class="edoovo-table-cell-tooltip" :content="`${row.healthCheckTime>1? row.healthCheckTime+' health check records':'1 health check record'}`"	v-else				
     		>
     			<i
     			  
     			  class="icon iconfontNew icona-SymptomsIcon_24dp"
     			  style="font-size: 24px; cursor: pointer"
     			></i></el-tooltip>
     </edoovo-table-column>
     <edoovo-table-column
       size="M"
       label="Remarks"
       prop="type"
       v-slot="{ row }"
	   align='center'
       :getTooltipText="(row) => `${row.remarks>1? row.remarks+' remarks':'1 remark'}`"
     		:tooltip='false'
     >
       <span v-if="row.remarks==0">-</span>
       
     		<el-tooltip popper-class="edoovo-table-cell-tooltip" :content="`${row.remarks>1? row.remarks+' remarks':'1 remark'}`"	v-else
     		><i
         
         class="icon iconfontNew icona-RemarkIcon_24dp"
         style="font-size: 24px; cursor: pointer"
       ></i></el-tooltip>
     </edoovo-table-column>
        <!-- <edoovo-table-column size="L"> </edoovo-table-column> -->
        <!-- ====== -->
        <!-- action -->
        <!-- ====== -->
        <edoovo-table-column
          size="L"
          v-slot="{ row }"
          key="action"
          v-if="
            ($buttonPermission('Attendance', 'Full') ||
            $buttonPermission('Attendance', 'Edit') ||
            $buttonPermission('Attendance', 'Create')) && (
			isToday
			)
          "
        >
          <el-tooltip
            content="Check-in"
            popper-class="edoovo-table-cell-tooltip"
            :visible-arrow="false"
            v-show="row.status !== 0"
          >
            <gg-submit-button
              @click="onSelectedStudentItem(row, 'checkInDialogVisible')"
            >
              Check-in
            </gg-submit-button>
          </el-tooltip>
          <el-tooltip
            content="Check-out"
            popper-class="edoovo-table-cell-tooltip"
            :visible-arrow="false"
            v-show="row.status === 0"
          >
            <gg-submit-button
              @click="onSelectedStudentItem(row, 'checkOutDialogVisible')"
            >
              Check-out
            </gg-submit-button>
          </el-tooltip>
          <el-tooltip
            content="Add temperature"
            popper-class="edoovo-table-cell-tooltip"
            :visible-arrow="false"
            v-show="row.status === 0"
          >
            <gg-submit-button
              @click="onSelectedStudentItem(row, 'temperatureDialogVisible')"
            >
              Add temperature
            </gg-submit-button>
          </el-tooltip>
          <el-tooltip
            content="Mark as absent"
            popper-class="edoovo-table-cell-tooltip"
            :visible-arrow="false"
            v-show="row.status !== 0 && row.status !== 1 && row.status !== 2"
          >
            <gg-submit-button
              @click="onSelectedStudentItem(row, 'absentDialogVisible')"
            >
              Mark as absent
            </gg-submit-button>
          </el-tooltip>
        </edoovo-table-column>
      </edoovo-table>
      <Snackbar
        :visible.sync="snackbarData.visible"
        :content="snackbarData.content"
      />
	  <!-- expost -->
	  <expostTab
		:classId="query.classId"
		:schoolId='$route.query.schoolId'
		:dialogVisible="expostTabVisible"
		@cancel="expostTabVisible = false"
	   />
      <!-- dialog -->
      <checkInDialog
        :dialogVisible="checkInDialogVisible"
        :studentName="chooseStudentItem.studentName"
		:firstName='chooseStudentItem.studentFirstName'
		:lastName='chooseStudentItem.studentLastName'
        :studentId="chooseStudentItem.studentId"
		:photo="chooseStudentItem.photo"
		:checkInDate='query.date'
        @cancel="checkInDialogVisible = false"
        @save="onCheckIn"
		:isEdit='false'
      />
      <checkOutDialog
        :dialogVisible="checkOutDialogVisible"
        :studentName="chooseStudentItem.studentName"
		:firstName='chooseStudentItem.studentFirstName'
		:lastName='chooseStudentItem.studentLastName'
        :studentId="chooseStudentItem.studentId"
		:photo="chooseStudentItem.photo"
		:checkInDate='query.date'
        @cancel="checkOutDialogVisible = false"
        @save="onCheckOut"
		:isEdit='false'
      />
      <temperatureDialog
        :dialogVisible="temperatureDialogVisible"
        :studentName="chooseStudentItem.studentName"
	:firstName='chooseStudentItem.studentFirstName'
	:lastName='chooseStudentItem.studentLastName'
		:photo="chooseStudentItem.photo"
        :id="chooseStudentItem.id"
		:studentId='chooseStudentItem.studentId'
		:checkInDate='query.date'
        @cancel="temperatureDialogVisible = false"
        @save="onAddTemperature"
		@editTime='temperatureeditTime'
		:isEdit='false'
      />
      <md-dialog-confirm
        :md-active.sync="absentDialogVisible"
        md-title="Mark as absent?"
        md-content="The student's status will change from no attendance to absent."
        md-confirm-text="Submit"
        md-cancel-text="Cancel"
        @md-cancel="() => {}"
        @md-confirm="onSaveAbsent"
      />
    </GgPage>
  </div>
</template>

<script>
import { Ajax, Utils } from "@/common";
import checkInDialog from "./listOrAddDialog-modules/check-in-dialog1";
import checkOutDialog from "./listOrAddDialog-modules/check-out-dialog1";
import temperatureDialog from "./listOrAddDialog-modules/temperature-dialog1";
import expostTab from "./listOrAddDialog-modules/expostTab";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "AttendanceStudent",
  components: {
    checkInDialog,
    checkOutDialog,
    temperatureDialog,
	expostTab
  },
  watch: {
	 
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
	isToday(){
		return true
		// return this.nowDate==this.query.date
	}
  },
  data() {
    return {
		timeData: {},
      healthCheckList: [],
      chooseStudentItem: {},
      checkInDialogVisible: false,
      checkOutDialogVisible: false,
      temperatureDialogVisible: false,
      absentDialogVisible: false,
	  expostTabVisible: false,
	  nowDate:"",
      query: {
        size: 20,
        current: 1,
        status: null,
        date: "",
        classId: "",
		schoolId:""
      },
      menuDatas: [
        {
          name: "Attendance",
          key: "0",
          active: true,
          children: [
            {
              name: "Daily",
              key: "",
            },
          ],
        },
      ],
      activeIndex: "",
      usersData: {
        ref: "usersTable",
        totalNum: 0,
        sort: { name: "", type: "" },
        isLoading: false,
        tableTitle: "Active Staff",
        tableData: [],
      },
      filterRenderData: [
        {   title: "Student name",filterKey: "studentName", type: "input" },
        {
          title: "Attendance",
          filterKey: "status",
          type: "radio",
          radioData: [
            { key: "Absent", value: 2 },
            { key: "Present", value: 1 },
          ],
        },
        // {
        //   title: "Symptoms",
        //   filterKey: "symptoms",
        //   type: "checkbox",
        //   checkboxData: [
        //     { key: "Cough", value: "Cough" },
        //     { key: "Runny Nose", value: "Runny Nose" }
        //   ]
        // }
      ],
      multKeys: [],
      snackbarData: {
        visible: false,
        content: "",
      },
	  warning:"No data for the class on this day."
    };
  },
  created() {
	  // time[1] = `${paramsDetail.timeData.hh < 10 ? `0${paramsDetail.timeData.hh}` : paramsDetail.timeData.hh}:${paramsDetail.timeData.mm}`
	  // params.supplementaryDate = time.join(' ') + ':00'
	  if(this.$route.query.classId){
		   this.query.classId = this.$route.query.classId; 
		   // this.query.schoolId = this.$route.query.schoolId;
	  }else{
		  this.query.schoolId = this.$route.query.schoolId;
	  }
   // || "06da6a3a6aab4b0fbc38b1af4815214b";
	console.log(this.$route.query)
    // this.query.date = Utils.formatTime(Date.now(), "{y}-{m}-{d}"); // 2021-01-10 00:00:00
	if(sessionStorage.getItem('date')){
		this.query.date = moment(sessionStorage.getItem('date')).format('YYYY-MM-DD')
	}else{
		this.query.date = moment().format('YYYY-MM-DD')
	}
	this.nowDate=Utils.formatTime(Date.now(), '{y}-{m}-{d}') 
    this.filterRenderData.push({
      title: "Symptoms",
      filterKey: "symptoms",
      type: "checkbox",
      checkboxData: this.healthCheckList,
    });
    this.getData();
    this.queryHealthCheckList();
  },
  methods: {
	  
    onChangeTopSelect(val) {
      // this.getData(val);
    },
   onDateClosed(val) {
   	console.log(this.$material.locale.dateFormat=='dd/MM/yyyy')
   	console.log(val)
   	console.log(moment(val,['DD/MM/YYYY']).format('YYYY-MM-DD'))
     if(this.$material.locale.dateFormat=='dd/MM/yyyy'){
     		this.query.date = moment(val,['DD/MM/YYYY']).format('YYYY-MM-DD')
     	sessionStorage.setItem('date', moment(val,['DD/MM/YYYY']).format('YYYY-MM-DD'))
     	
     }else{
     		this.query.date = Utils.formatTime(val, '{y}-{m}-{d}')
     	sessionStorage.setItem('date', Utils.formatTime(val, '{y}-{m}-{d}'))
     }
     this.getData();
     
   },
   openMessageBox(messageObj, callback) {
     this.$confirm(messageObj.desc, messageObj.title, {
       cancelButtonText: messageObj.cancelTxt,
       confirmButtonText: messageObj.confirmTxt,
       cancelButtonClass: "gg_cancel_button",
       confirmButtonClass: "gg_submit_button",
       customClass: "gg_confirm",
       showClose:false,
       callback: (action) => {
         if (action == "confirm") {
           callback(action);
         }
       },
     });
   },
    onSelectedStudentItem(row, valueKey) {
		console.log(valueKey=='absentDialogVisible')
	  if(valueKey=='absentDialogVisible'){
		  this.chooseStudentItem = row;
		  let messageObj = {
		    title: "Mark as absent?",
		    desc: "The student's status will change from no attendance to absent.",
		    cancelTxt: "Cancel",
		    confirmTxt: "Submit",
		  };
		  let callback = () => {
		    this.onSaveAbsent();
		  };
		  this.openMessageBox(messageObj, callback);
	  }else{
		  this[valueKey] = true;
		  this.chooseStudentItem = row;
	  }
	 
	  // console.log(this.chooseStudentItem)
    },
    onCheckIn(paramsDetail) {
		 // console.log(paramsDetail)
		 // return false
      let params = {
        timeLine: paramsDetail.paramsDetail,
      };
      Object.assign(params.timeLine, {
        checkInOutId: this.chooseStudentItem.id,
        operation: 0,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.chooseStudentItem.schoolId,
        studentId: this.chooseStudentItem.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b',
        checkTime: moment().format("DD/MM/YYYY hh:mm A"), // '10:00:00 AM',
         userType: paramsDetail.paramsDetail.dropUserType=="pickup"?1:0,
		startDate:moment(moment().format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		endDate:moment(moment().format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
        // dropOffUser: '',
        // checkValue: '36.00',
        // symptoms: 'symptoms',
        // remarks: 'remarks'
      });
	  
	  params.startDate = moment(moment().format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss')
	  params.endDate = moment(moment().format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
	  let time = moment().format("YYYY-MM-DD HH:mm:ss").split(' ')
	  time[0] = this.query.date
	  time[1] = `${paramsDetail.timeData.hh}:${paramsDetail.timeData.mm}:${moment().format("ss")}`
	  time[2] = paramsDetail.timeData.state
	  params.supplementaryDate = moment(time.join(' '),['YYYY-MM-DD HH:mm:ss A']).utc().format('YYYY-MM-DD HH:mm:ss')
	  params.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
	  params.timeLine.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
	  // console.log(params)
	  //  return false
	  params.startDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
	  params.endDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
      Ajax.post("/attendance/checkInOut/checkInV1", params).then((res) => {
        if (res.code === "0000") {
          this.checkInDialogVisible = false;
          this.snackbarData = { visible: true, content: res.message };
          this.getData();
        }
      });
    },
    onCheckOut(paramsDetail) {
      let params = {
        timeLine: paramsDetail.paramsDetail,
      };
      Object.assign(params.timeLine, {
        checkInOutId: this.chooseStudentItem.id,
        operation: 0,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.chooseStudentItem.schoolId,
        studentId: this.chooseStudentItem.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b',
        checkTime: moment().format("DD/MM/YYYY hh:mm A"), // '10:00:00 AM',
         userType: paramsDetail.paramsDetail.dropUserType=="pickup"?1:0,
        status: 1,
        // dropOffUser: '',
        // checkValue: '36.00',
        // symptoms: 'symptoms',
        // remarks: 'remarks'
      });
	  	  params.startDate = moment(moment().format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss')
	  	  params.endDate = moment(moment().format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
		  
		  let time = moment().format("YYYY-MM-DD HH:mm").split(' ')
		  time[0] = this.query.date
		  time[1] = `${paramsDetail.timeData.hh}:${paramsDetail.timeData.mm}:${moment().format("ss")}`
		  time[2] = paramsDetail.timeData.state
		  params.supplementaryDate = moment(time.join(' '),['YYYY-MM-DD HH:mm:ss A']).utc().format('YYYY-MM-DD HH:mm:ss')
		  // console.log(params)
		 params.startDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		 params.endDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
		params.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
		params.timeLine.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
		  //  return false
      Ajax.post("/attendance/checkInOut/checkOutV1", params).then((res) => {
        if (res.code === "0000") {
          this.checkOutDialogVisible = false;
          this.snackbarData = { visible: true, content: res.message };
          this.getData();
        }
      });
    },
	temperatureeditTime (e) {
		this.timeData = e
	},
    onAddTemperature(tempValue) {
		let time = moment().format("YYYY-MM-DD HH:mm").split(' ')
		time[0] = this.query.date
		time[1] = `${tempValue.timeData.hh}:${tempValue.timeData.mm}:${moment().format("ss")}`
		time[2] = tempValue.timeData.state
      let params = {
        checkInOutId: this.chooseStudentItem.id,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        temperature: tempValue.tempValue,
		temperatureType: tempValue.timeData.states ? 3 : 4,
		symptomsList: tempValue.paramsDetail.symptomsList,
		remarks: tempValue.paramsDetail.remarks,
        supplementaryDate:moment(time.join(' '),['YYYY-MM-DD HH:mm:ss A']).utc().format('YYYY-MM-DD HH:mm:ss'), // '10:00:00 AM'
      };
	  
		  // params.supplementaryDate = moment(time.join(' '),['YYYY-MM-DD HH:mm A']).utc().format('YYYY-MM-DD HH:mm:ss')
	  // console.log(params)
	  // return false
	  params.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
      Ajax.post("/attendance/timeLine/addTempV1", params).then((res) => {
        if (res.code === "0000") {
          this.temperatureDialogVisible = false;
          this.snackbarData = { visible: true, content: res.message };
          this.getData();
        }
      });
    },
    onSaveAbsent(data) {
      let params = {
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.chooseStudentItem.schoolId,
        studentId: this.chooseStudentItem.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b'
		// absentTime:moment().format("DD/MM/YYYY hh:mm A"),
		// startDate:moment(moment().format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		// endDate:moment(moment().format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
		supplementaryDate:moment(moment(`${this.query.date} ${moment().format('HH:mm:ss')}`)).utc().format('YYYY-MM-DD HH:mm:ss'),
		absentTime:moment(moment(`${this.query.date} ${moment().format('HH:mm:ss')}`)).utc().format("DD/MM/YYYY hh:mm A"),
		startDate:moment(moment(this.query.date).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		endDate:moment(moment(this.query.date).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
      };
	  params.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
      Ajax.post("/attendance/checkInOut/markAsAbsentV1", params).then((res) => {
        if (res.code === "0000") {
          this.absentDialogVisible = false;
          this.snackbarData = { visible: true, content: res.message };
          this.getData();
        }
      });
    },
    toTimelinePage(row) {
      this.$router.push({
        name: "attendanceStudentTimeline",
        query: {
          id: row.id,
          studentId: row.studentId,
          classId: this.$route.query.classId,
		  className:this.$route.query.className,
		  status:row.status,
		  schoolId:this.$route.query.schoolId 
        },
      });
    },
    getData() {
		
		// if(sessionStorage.getItem('spec')){
		// 	 if(moment(this.query.date).unix()>moment(sessionStorage.getItem('spec')).unix()){
		// 		 this.warning='No data for the class on this day.'
		// 		 this.usersData.tableData = []
				 
		// 		 return false
		// 	 }else{
		// 		 this.warning='No student yet.'
		// 	 }
			
		// }else{
		// 	this.warning='No student yet.'
		// }
      this.query.userId = this.user.userId;
	  let query = Object.assign({},this.query) 
	  query.startDate = moment(this.query.date).utc().format('YYYY-MM-DD HH:mm:ss')
	  let url 
	  if(this.query.schoolId){
		  url =  "/attendance/checkInOut/getStudentListBySchoolIdEnhanceV1"
	  }else{
		  url = '/attendance/checkInOut/getStudentListByClassIdEnhanceV1'
		  query.schoolId = this.$route.query.schoolId
	  }
      Ajax.post(
       url,
        query
      ).then((res) => {
		  console.log(res)
		  if(res.code=="0017"){
			  this.warning='No data for the class on this day.'
			  this.usersData.tableData = [];
			  this.usersData.totalNum = 0
			  return false
		  }
        let { records, total } = res.data;
        this.usersData.tableData = records;
		this.usersData.totalNum = res.data.total
		// 0210cy table组件中$index不知道为什么每次都是1 研究了一会无果只能手动追加了 如有好办法删除下面的循环
		this.usersData.tableData.forEach((item,index)=>{
			if(item.checkinTime){
				
				item.checkinTime=moment.utc(item.checkinTime).local().format("h:mm A")
			}
			if(item.checkoutTime){
				item.checkoutTime=moment.utc(item.checkoutTime).local().format("h:mm A")
			}
			if(item.inClassTime){
				item.inClassTime=moment.utc(item.inClassTime).local().format("h:mm A")
			}
			item.dataIndex=index
		})
      
	  });
    },
    queryHealthCheckList() {
      this.healthCheckList.splice(0, this.healthCheckList.length);
      Ajax.post(`/usermanage/setting/getList`, {
        dataLevel: "healthCheck",
        dataType: "illness",
        userId: this.user.userId,
      }).then((res) => {
        res.data.forEach((item) => {
          item.key = item.value;
          this.healthCheckList.push(item);
        });
      });
      Ajax.post(`/usermanage/setting/getList`, {
        dataLevel: "healthCheck",
        dataType: "visualCheck",
        userId: this.user.userId,
      }).then((res) => {
        res.data.forEach((item) => {
          item.key = item.value;
          this.healthCheckList.push(item);
        });
      });
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.query.status = key;
      this.usersData.tableTitle = item.name;
      this.getData();
    },
    _onSortChange(sort) {
      this.usersData.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      // console.log(sort)
      this.getData();
    },
    _pageChange(val) {
      this.query.current = val;
      this.getData();
    },
    _pageSizeChange(val) {
      this.query.size = val;
      this.getData();
    },
    _filterCallback(data) {
      let obj = {};
      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });
	  if(this.$route.query.className=='All'){
		  this.query = {
		    size: 20,
		    current: 1,
		    status: null,
		    date: this.query.date,
			schoolId:this.$route.query.schoolId
		  };
	  }else{
		  this.query = {
		    size: 20,
		    current: 1,
		    status: null,
		    date: this.query.date,
		    classId: this.$route.query.classId,
		  };
	  }
      
      Object.assign(this.query, obj);
      this.getData();
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .Temperature {
	>p {
		width: 74px;
		/* font-family: OpenSans-Bold; */
		font-family: 'opensans-semibold';
		font-size: 12px;
		color: #B4B4B4;
	}
	span {
		padding-left: 12px;
		font-size: 12px;
		color: #202124;
		font-family: OpenSans-Bold;
	}
}
/deep/ .floats {
	position: relative;
	.errorText {
		font-family: Roboto;
		color: #ea4335;
		position: absolute;
		left: 0;
		bottom: -20px;
		z-index: 100;
		font-size: 12px;
	}
	>span {
		font-family: Roboto;
		color: #202124 !important;
		font-size: 16px !important;
		font-weight: 400 !important;
	}
	input {
		font-family: Roboto;
		color: #202124 !important;
		font-size: 16px !important;
		border: 0;
		font-weight: 400 !important;
		border-radius: 0;
		border-bottom: 1px solid #E0E0E0;
	}
	p {
		font-size: 12px;
		font-family: Roboto;
	}
	.time-Item {
		.right {
			span {
				font-family: Roboto;
				color: #202124;
				font-weight: 400 !important;
				font-size: 12px !important;
			}
		}
	}
}
/deep/ .dialog-content-Top {
	margin-bottom: 19px !important;
}
 /deep/html .md-overlay {
  /* z-index: 9!important; */
}
/deep/.table-cell-img{
	/* padding: 3px; */
	img{
		border-radius: 50%;
	}
}
.red{
	background-color: #ff7d72!important;
}
.green{
	background-color: #3db175!important;
}
.absent{
	opacity: 0.5;
	/* background-color: #ddd; */
}
.statusImg{
	display: inline-block;
	    padding: 3px;
	    width: 38px;
	    border-radius: 50%;
		margin-right: 5px;
			/* background-color: #ddd; */
}
/deep/.previewImg{
	display: none;
}
/deep/.md-field .md-input, .md-field .md-textarea{
	 font-size: 14px;
 }
 /deep/.md-field label{
	  font-size: 14px;
 }
 /deep/.ggPage-main-left{
	 display: none;
 }

/deep/ .ggPage-main {
	.centerPadding2 {
		padding-left: 80px;
		padding-bottom: 72px;
	}
}
.selectPhotoBtn{
	width: 65px;
	    height: 32px;
	    line-height: 32px;
	    background: #1a73e8;
	    text-align: center;
	    border-radius: 4px;
	    font-size: 13px;
	    font-family: OpenSans-Bold;
	    color: #ffffff;
}
/deep/.ggPage-main{
	padding-top: 33px!important;
}
/deep/.edoovo-table-cell.edoovo-table-cell-size-L{
	width: 312px;
}
/deep/.edoovo-table-cell-inner{
	margin-right: 32px;
}
/deep/.edoovo-table-cell-action .edoovo-table-cell-inner > *:last-child{
	margin-right: 0;
}
</style>
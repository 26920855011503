<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
		  {
		    routeName: 'locations',
		    name: user.schoolName,
		  }, {
		    routeName: 'attendance',
		    name: 'Attendance',
		  },
        {
			routeName: 'attendanceStudent',
          name: $route.query.className,
        },
		{
          name: `${studentStatusData.firstName} ${studentStatusData.lastName}`,
        }
      ]"
      slot="breadcrumb"
    />
    <GgPage
   
      pageType="1"
      title="Attendance"
    >
      <div>
        <GgPageHeader
          :title='`${studentStatusData.firstName||""} ${studentStatusData.lastName||""}`'
          :menuData="menuData"
          :subTitle1="studentStatusData.studentId"
		
          @menuCallback="onMenuCallback"
        >
          <div slot="title-label"></div>
          <div slot="avatarHeader" class="avatar">
			
			  <div :class='studentDetailData.status === 0?"green":(studentDetailData.status === 2?"absent":(studentDetailData.status === 1? "red":""))'	 class="statusImg">
			  			<head-img
			  			  class="imgBox"
			  			  :src="studentStatusData.photo"
			  			  line-height="52"
						  :firstName="`${studentStatusData.firstName}`"
						  :lastName="`${studentStatusData.lastName}`"
			  			/>
			  </div>
           
          </div>
          <div slot="info">
            <div class="title-status mar-b-0">{{ studentStatusData.status }}</div>
          </div>
        </GgPageHeader>
        <section-info :data="dataList" title="Timeline" :subTitle='getDate()'>
          <template slot="custom-html">
            <edoovo-table
              v-if="!timelineList.length"
              height="100%"
              rowKey="id"
              defaultValue="-"
            >
              <!-- ===== -->
              <!-- 空状态 -->
              <!-- ===== -->
              <div slot="empty" style="text-align: center">
                <div
                  style="
                    width: 213px;
                    text-align: center;
                    border-radius: 50%;
                    margin-bottom: 12px;
                    line-height: 200px;
                  "
                >
                  <img
                    style="width: 100%"
                    :src="require('@/assets/images/products/noData.svg')"
                  />
                </div>
                <p style="font-size: 22px">No attendance info yet.</p>
              </div>
            </edoovo-table>
            <div class="timeline-wrap" v-else>
              <el-timeline>
                <el-timeline-item v-for="(item, key) in timelineList" :key="key">
                  <!-- <i class="iconfont iconyiquxiao" slot="dot"></i> -->
                  <img v-if="(item.action=='Temperature' ||item.action=='In class temperature' ||item.action=='Ad hoc temperature')" style="width: 45px; margin-left: -18px;" :src="timelineTemperatureSvg" alt="" slot="dot" />
                  <img v-if="item.action === 'Check-in'" style="width: 45px; margin-left: -18px;" :src="checkInSvg" alt="" slot="dot" />
                  <img v-if="item.action === 'Check-out'" style="width: 45px; margin-left: -18px;" :src="checkOutSvg" alt="" slot="dot" />
                  <p class="timeline-time">{{ dateFormat(item.checkTime) }}</p>
                  <div @click="onSelectedStudentItem(item)">
                    <p>{{ item.action }} <span v-if="item.edit" style="margin-left: 5px; color: #B4B4B4; font-size: 12px;">Edited</span></p>
                    <p style="font-family: 'Roboto';">
                      <span v-if="item.temperatrue" style="margin-right: 10px;">Temperature : {{ item.temperatrue }}°C</span>
                      <span v-if="item.byUserFirstName || item.byUserLastName">
						{{getDes(item)}}
						{{item.visitor || (item.action!='Check-out' && item.action!='Check-in') ?'':item.relationShip=="Default role"?"":item.relationShip}}
                        <head-img
							v-if='!item.visitor'
						:src="item.deleted?item.byUserPhoto:(item.visitor==false && (item.action!='Check-out')&&(item.action!='Check-in')?item.byUserPhoto:item.dropOffOrPickUpPhoto)"  
                          :firstName="item.byUserFirstName"
                          :lastName="item.byUserLastName"
                          line-height="20"
                          style="display: inline-block; vertical-align: middle;"
						  class='smft' 
                        />
                      {{ item.byUserFirstName}} {{item.byUserLastName}}
                      </span>
                    </p>
                    <div style="padding-top: 10px;" v-if="(item.action === 'Check-in'|| item.action=='In class temperature' || item.action=='Ad hoc temperature')&&!item.deleted ">
                      <p class="icon-wrap" style="margin-right: 8px;" v-if="item.symptomsList">
                        <i class="icon iconfontNew icona-SymptomsIcon_24dp" style="font-size: 16px;" :title="item.symptomsList && item.symptomsList.join(',')"></i>
                      </p>
                      <p class="icon-wrap" v-if="item.remarks">
                        <i class="icon iconfontNew icona-RemarkIcon_24dp" style="font-size: 16px;" :title="item.remarks"></i>
                      </p>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </template>
        </section-info>
      </div>
    </GgPage>
    <!-- <div class="wraper"></div> -->
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
    <!-- dialog -->
    <checkInDialog
      :dialogVisible="checkInDialogVisible"
      :isEditable="isEditable"
	  :checkInDate='queryDate'
      :checkInChoutId="studentDetailData.id"
	  :photo='studentStatusData.photo'
      :studentName="`${studentStatusData.firstName} ${studentStatusData.lastName}`"
	  :firstName='studentStatusData.firstName'
	  		:lastName='studentStatusData.lastName'
      :chooseCheckInOutItem="chooseCheckInOutItem"
      :studentId="studentDetailData.studentId"
      @cancel="checkInDialogVisible = false"
      @save="onCheckIn"
      @delete="handleDeleteCheckIn"
    />
    <checkOutDialog
      :dialogVisible="checkOutDialogVisible"
      :isEditable="isEditable"
	  :checkInDate='queryDate'
	  :photo='studentStatusData.photo'
      :checkInChoutId="studentDetailData.id"
      :studentName="`${studentStatusData.firstName} ${studentStatusData.lastName}`"
	  :firstName='studentStatusData.firstName'
	  		:lastName='studentStatusData.lastName'
      :studentId="studentDetailData.studentId"
      :chooseCheckInOutItem="chooseCheckInOutItem"
      @cancel="checkOutDialogVisible = false"
      @save="onCheckOut"
      @delete="handleDeleteCheckOut"
    />
    <temperatureDialog
      :dialogVisible="temperatureDialogVisible"
      :isEditable="isEditable"
	  :symptomsList='chooseCheckInOutItem.symptomsList'
      :checkInChoutId="studentDetailData.id"
      :studentName="`${studentStatusData.firstName} ${studentStatusData.lastName}`"
	  :firstName='studentStatusData.firstName'
	  		:lastName='studentStatusData.lastName'
	  :photo='studentStatusData.photo'
	  :checkInDate='queryDate'
      :chooseCheckInOutItem="chooseCheckInOutItem"
      :editTempValue="chooseCheckInOutItem.temperatrue"
	  :studentId='chooseCheckInOutItem.studentId'
      @cancel="temperatureDialogVisible = false"
      @save="onAddTemperature"
      @delete="handleDeleteTemperature"
    />
    <md-dialog-confirm
      :md-active.sync="absentDialogVisible"
      md-title="Mark as absent?"
      md-content="The student's status will change from no attendance to absent."
      md-confirm-text="Submit"
      md-cancel-text="Cancel"
      @md-cancel="() => {}"
      @md-confirm="onSaveAbsent"
    />
	<!-- Adddialog -->
	<checkInDialog1
	  :dialogVisible="checkInDialogVisible1"
	  :isEdit="true"
	  :checkInChoutId="studentDetailData.id"
	  :photo='studentStatusData.photo'
	  :studentName="`${studentStatusData.firstName} ${studentStatusData.lastName}`"
	  :firstName='studentStatusData.firstName'
	  		:lastName='studentStatusData.lastName'
	  :chooseCheckInOutItem="chooseCheckInOutItem"
	  :studentId="studentDetailData.studentId"
	  :checkInDate='queryDate'
	  @cancel="checkInDialogVisible1 = false"
	  @save="onCheckIn"
	/>
	<checkOutDialog1
	  :dialogVisible="checkOutDialogVisible1"
	  :isEdit="true"
	  :photo='studentStatusData.photo'
	  :checkInChoutId="studentDetailData.id"
	  :studentName="`${studentStatusData.firstName} ${studentStatusData.lastName}`"
	  :firstName='studentStatusData.firstName'
	  		:lastName='studentStatusData.lastName'
	  :studentId="studentDetailData.studentId"
	  :chooseCheckInOutItem="chooseCheckInOutItem"
	  :checkInDate='queryDate'
	  @cancel="checkOutDialogVisible1 = false"
	   @save="onCheckOut"
	/>
	<temperatureDialog1
	  :dialogVisible="temperatureDialogVisible1"
	  :isEdit="true"
	  :isEditable="isEditable"
	  :checkInChoutId="studentDetailData.id"
	  :studentName="`${studentStatusData.firstName} ${studentStatusData.lastName}`"
	  :firstName='studentStatusData.firstName'
	  		:lastName='studentStatusData.lastName'
	  :photo='studentStatusData.photo'
	  :id="studentDetailData.id"
	  :chooseCheckInOutItem="chooseCheckInOutItem"
	  :editTempValue="chooseCheckInOutItem.temperatrue"
	  :checkInDate='queryDate'
	  @cancel="temperatureDialogVisible1 = false"
	    @save="onAddTemperature"
		@editTime='temperatureeditTime'
	/>
  </div>
</template>

<script>
import { Ajax, Utils } from "@/common";
import { mapState } from "vuex";
import checkInDialog from "./dialog-modules/check-in-dialog";
import checkOutDialog from "./dialog-modules/check-out-dialog";
import temperatureDialog from "./dialog-modules/temperature-dialog";
import checkInDialog1 from "./listOrAddDialog-modules/check-in-dialog1";
import checkOutDialog1 from "./listOrAddDialog-modules/check-out-dialog1";
import temperatureDialog1 from "./listOrAddDialog-modules/temperature-dialog1";
import moment from 'moment';
export default {
  name: "Timeline",
  components: {
    checkInDialog,
    checkOutDialog,
    temperatureDialog,
	checkInDialog1,
	checkOutDialog1,
	temperatureDialog1,
  },
  data() {
    return {
		timeData: {},
      timelineTemperatureSvg: require('@/assets/images/icons/timeline_temperature.svg'),
      checkInSvg: require('@/assets/images/icons/check_in.svg'),
      checkOutSvg: require('@/assets/images/icons/check_out.svg'),
      queryDate: '',
      studentDetailData: {},
      studentStatusData: {},
      // studentData: {},
      timelineList: [],
      chooseCheckInOutItem: {},
      checkInDialogVisible: false,
      checkOutDialogVisible: false,
      temperatureDialogVisible: false,
	  checkInDialogVisible1: false,
	  checkOutDialogVisible1: false,
	  temperatureDialogVisible1: false,
      absentDialogVisible: false,
      isEditable: true,
      dataList: [],
      snackbarData: {
        visible: false,
        content: "",
      },
      menuData: [
        {
          label: 'Check in',
          isDisabled: true
        }, {
          label: 'Check out',
          isDisabled: true
        }, {
          label: 'Mark as absent',
          isDisabled: true
        }, {
          label: 'Add temperature',
          isDisabled: true
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
	
  },
  created() {
	  
	 if(sessionStorage.getItem('date')){
	 	this.queryDate = Utils.formatTime(sessionStorage.getItem('date'), '{y}-{m}-{d}')
	 }else{
		this.queryDate = Utils.formatTime(Date.now(), '{y}-{m}-{d}') 
	 }

    this.queryStudentDetail()
    this.queryStudentStatus()
	this.studentDetailData.status=this.$route.query.status
  },
  methods: {
	  getDate(){
		  console.log(this.queryDate)
	  return 	moment(this.queryDate,["YYYY-MM-DD"]).format("dddd, DD MMMM YYYY")  
	    },
	  temperatureeditTime (e) {
	  	this.timeData = e
		console.log(e)
	  },
	  getDes(item){
		 if(item.operationRecord!='' && item.operationRecord!=null){
			return item.operationRecord
		 }else{item.action=='Check-out'
			if(item.action=='Check-in' ){
				if(item.visitor){
					return 'Drop-off by: visitor:'
				}else{
				return 'Drop-off by:'
				}
				
			}else if(item.action=='Check-out' ){
				if(item.visitor){
					return 'Pick-off: visitor'
				}else{
				return 'Pick-up:'
				}
				
			}else if(item.action=='Temperature' ||item.action=='In class temperature' ||item.action=='Ad hoc temperature'){
					return 'Taken by:'
			} else if(item.action=='Absent'){
				return 'Marked by:'
			} 
			 
		 }
		 
	  },
	  dateFormat(date) {
	    if (!date) {
	      return "-";
	    }
	    return moment.utc(date,["YYYY-MM-DD hh:mm:ss A"]).local().format("h:mm A");
	  },
    onDateClosed(val) {
      let tim = Date.parse(val)
      this.queryDate = Utils.formatTime(tim, '{y}-{m}-{d}')
      this.queryTimelineData()
      this.queryStudentDetail()
      this.queryStudentStatus()
    },
    onForwardDate(val) {
      // this.getData(val);
      console.log('forward', val)
    },
    onNextDate(val) {
      // this.getData(val);
      console.log('next', val)
    },
    handleMenuDataStatus(){
      let status = this.studentDetailData.status
	  
      this.menuData.forEach(item => {
        if (status === 0) {
          if (item.label === 'Check out' || item.label === 'Add temperature') {
            item.isDisabled = false
          }else{
			     item.isDisabled = true
		  }
        } else if (status === 1) {
          if (item.label === 'Check in') {
            item.isDisabled = false
          }else{
			     item.isDisabled = true
		  }
        } else if (status === 2) {
          if (item.label === 'Check in') {
            item.isDisabled = false
          }else{
			     item.isDisabled = true
		  }
        } else {
          if (item.label === 'Check in' || item.label === 'Mark as absent') {
            item.isDisabled = false
          }else{
			     item.isDisabled = true
		  }
        }
      })
	  if(this.$buttonPermission('Attendance', 'Full') ||
            this.$buttonPermission('Attendance', 'Edit') || this.$buttonPermission('Attendance', 'Create')){
	  			
	  }else{
		  this.menuData=[]
	  }
	  // if(this.queryDate != Utils.formatTime(Date.now(), '{y}-{m}-{d}') ){
	  // 			 this.menuData=[]
	  // }
    },
    onMenuCallback(item){
      this.isEditable = false
      if (item.label === 'Check in') {
        this.checkInDialogVisible1 = true
      } else if (item.label === 'Check out') {
        this.checkOutDialogVisible1 = true
      } else if (item.label === 'Add temperature') {
        this.temperatureDialogVisible1 = true
      } else if (item.label === 'Mark as absent') {
        // this.absentDialogVisible = true
		let messageObj = {
		  title: "Mark as absent?",
		  desc: "The student's status will change from no attendance to absent.",
		  cancelTxt: "Cancel",
		  confirmTxt: "Submit",
		};
		let callback = () => {
		  this.onSaveAbsent();
		};
		this.openMessageBox(messageObj, callback);
      }
    },
    onCheckIn(paramsDetail){
		// console.log(paramsDetail)
		// console.log(this.studentDetailData)
      let url = this.isEditable ? '/attendance/checkInOut/updateCheckInV1' : '/attendance/checkInOut/checkInV1'
      let params = {
        timeLine: paramsDetail.paramsDetail
      }
      Object.assign(params.timeLine, {
        checkInOutId: this.studentDetailData.id, // this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,
        operation: 0,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.studentDetailData.schoolId,
        studentId: this.studentDetailData.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b',
        checkTime: moment().format("DD/MM/YYYY hh:mm A"), // '10:00:00 AM',
        userType: paramsDetail.paramsDetail.dropUserType=="pickup"?1:0,
		startDate:moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		endDate:moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
      })
	  params.startDate = moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss')
	  params.endDate = moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
	  let time = moment().format("YYYY-MM-DD HH:mm").split(' ')
	  time[0] = this.queryDate
	  time[1] = `${paramsDetail.timeData.hh}:${paramsDetail.timeData.mm}:${moment().format("ss")}`
	  time[2] = paramsDetail.timeData.state
	  params.supplementaryDate = moment(time.join(' '),['YYYY-MM-DD HH:mm:ss A']).utc().format('YYYY-MM-DD HH:mm:ss')
	 params.startDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
	 params.endDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
	  params.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
	  params.timeLine.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
	  // console.log(params)
	  //  return false
      Ajax.post(url, params).then(res => {
        if (res.code === '0000') {
          this.checkInDialogVisible = false
		  this.checkInDialogVisible1 = false
          this.snackbarData = { visible: true, content: res.message };
          this.queryTimelineData();
          this.queryStudentDetail()
        }
      })
    },
    onCheckOut(paramsDetail) {
      let url = this.isEditable ? '/attendance/checkInOut/updateCheckOutV1' : '/attendance/checkInOut/checkOutV1'
      let params = {
        timeLine: paramsDetail.paramsDetail
      }
      Object.assign(params.timeLine, {
        checkInOutId: this.studentDetailData.id, // this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,
        operation: 0,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.studentDetailData.schoolId,
        studentId: this.studentDetailData.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b',
        checkTime: moment().format("DD/MM/YYYY hh:mm A"), // '10:00:00 AM',
        userType: paramsDetail.paramsDetail.dropUserType=="pickup"?1:0,
		startDate:moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		endDate:moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss"),
        status: 1
      })
	  	params.startDate = moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss')
	  	params.endDate = moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
		let time = moment().format("YYYY-MM-DD HH:mm").split(' ')
		time[0] = this.queryDate
		time[1] = `${paramsDetail.timeData.hh}:${paramsDetail.timeData.mm}:${moment().format("ss")}`
		time[2] = paramsDetail.timeData.state
		params.supplementaryDate = moment(time.join(' '),['YYYY-MM-DD HH:mm:ss A']).utc().format('YYYY-MM-DD HH:mm:ss')
		// console.log(params)
		params.startDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		params.endDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
		params.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
		params.timeLine.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
		//  return false
      Ajax.post(url, params).then((res) => {
        if (res.code === '0000') {
          this.checkOutDialogVisible = false
		   this.checkOutDialogVisible1 = false
          this.snackbarData = { visible: true, content: res.message };
          this.queryTimelineData();
          this.queryStudentDetail()
        }
      });
    },
    onAddTemperature(tempValue) {
	
      let url = this.isEditable ? '/attendance/timeLine/updateTempV1' : '/attendance/timeLine/addTempV1'
	  let time = moment().format("YYYY-MM-DD HH:mm").split(' ')
	  time[0] = this.queryDate
	  time[1] = `${tempValue.timeData.hh}:${tempValue.timeData.mm}:${moment().format("ss")}`
	  time[2] = tempValue.timeData.state
      let params
	   
	   if(this.isEditable){
		   
		   params = {
        checkInOutId: this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,//this.studentDetailData.id, // this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        temperature: tempValue.tempValue,
		temperatureType: tempValue.timeData.states ? 3 : 4,
		symptomsList: tempValue.paramsDetail.symptomsList,
		remarks: tempValue.paramsDetail.remarks,
		supplementaryDate:moment(time.join(' '),['YYYY-MM-DD HH:mm:ss A']).utc().format('YYYY-MM-DD HH:mm:ss'), // '10:00:00 AM'
        // time: Utils.getDateNowByAmPm(), // '10:00:00 AM'
		// id:this.chooseCheckInOutItem.id
      };
	   }else{
		 params = {
		   checkInOutId: this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,//this.studentDetailData.id, // this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,
		   operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
		   temperature: tempValue.tempValue,
		   temperatureType: tempValue.timeData.states ? 3 : 4,
		   symptomsList: tempValue.paramsDetail.symptomsList,
		   remarks: tempValue.paramsDetail.remarks,
		   supplementaryDate:moment(time.join(' '),['YYYY-MM-DD HH:mm:ss A']).utc().format('YYYY-MM-DD HH:mm:ss'), // '10:00:00 AM'
		   // temperature: tempValue,
		   // time: Utils.getDateNowByAmPm(), // '10:00:00 AM'
		 		// time:moment().format("DD/MM/YYYY hh:mm A"),
		 		// id:this.chooseCheckInOutItem.id
		 };  
	   }
	   params.checkTime = moment(params.supplementaryDate).format('DD/MM/YYYY hh:mm A')
	   
	  //  console.log(params)
	  // return false
      Ajax.post(url, params).then(res => {
        if (res.code === '0000') {
          this.temperatureDialogVisible = false
		  this.temperatureDialogVisible1 = false
          // this.snackbarData = { visible: true, content: "Student temperature has been edited" };
		  this.snackbarData = { visible: true, content: res.message };
          this.queryTimelineData();
          this.queryStudentDetail()
        }
      })
    },
    onSaveAbsent(data) {
      let params = {
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.studentDetailData.schoolId,
        studentId:this.$route.query.studentId,
        classId: this.$route.query.classId ,
		supplementaryDate:moment(moment(`${this.queryDate} ${moment().format('HH:mm:ss')}`)).utc().format('YYYY-MM-DD HH:mm:ss'),
		absentTime:moment(moment(`${this.queryDate} ${moment().format('HH:mm:ss')}`)).utc().format("DD/MM/YYYY hh:mm A"),
		startDate:moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		endDate:moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
      };
	  // params.startDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
	  // params.endDate = moment(moment(params.supplementaryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
      Ajax.post("/attendance/checkInOut/markAsAbsentV1", params).then((res) => {
        if (res.code === '0000') {
          this.absentDialogVisible = false
          this.snackbarData = { visible: true, content: res.message };
          this.queryTimelineData();
          this.queryStudentDetail()
        }
      });
    },
    onSelectedStudentItem(row){
		if(row.action=='Absent' ){
			return false
		}
		if(row.operationRecord!='' && row.operationRecord!=null){
			return false
		}
      this.chooseCheckInOutItem = row
      this.isEditable = true
      if (row.action === 'Check-in') {
        this.checkInDialogVisible = true
      } else if (row.action === 'Check-out') {
        this.checkOutDialogVisible = true
      } else {
        this.temperatureDialogVisible = true
      }
    },
    queryStudentDetail(){
      Ajax.get("/attendance/checkInOut/getCheckInOutByStudentId", {
        studentId: this.$route.query.studentId,
        schoolId: this.user.locationId,
		startDate:moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		endDate:moment(moment(this.queryDate).format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
      }).then(res => {
        this.studentDetailData = res.data
		this.studentDetailData.studentId = this.$route.query.studentId
        // this.handleMenuDataStatus()
		this.queryTimelineData()
      })
    },
    queryStudentStatus(){
      Ajax.get("/usermanage/student/selectByStuId", {
        stuId: this.$route.query.studentId
      }).then(res => {
        this.studentStatusData = res.data
      })
    },
    queryTimelineData(){
      let params = {
        // checkInOutId: this.$route.query.id, // || "2CEXyDX6kTtsu8vNvnangGeFmSinZqTA",
        studentId: this.$route.query.studentId,
        startDate: moment(this.queryDate).utc().format('YYYY-MM-DD HH:mm:ss'),
		classId:this.$route.query.classId,
      }
      Ajax.get("/attendance/timeLine/getTimeLineEnhanceV1", params).then(res => {
        this.timelineList = res.data
		res.data = res.data.filter(item=>{return !item.deleted})
		// console.log(arr)
		if(res.data.length==0){
			this.studentDetailData.status=null
		}else{
			if(res.data[0].action=='Temperature' || res.data[0].action=='Check-in' || res.data[0].action=='In class temperature' || res.data[0].action=='Ad hoc temperature'){
				this.studentDetailData.status=0
			}else if(res.data[0].action=='Check-out'){
				this.studentDetailData.status=1
			}else{
				this.studentDetailData.status=2
			}
		}
		 this.handleMenuDataStatus()
		
      })
    },
	openMessageBox(messageObj, callback) {
	  this.$confirm(messageObj.desc, messageObj.title, {
	    cancelButtonText: messageObj.cancelTxt,
	    confirmButtonText: messageObj.confirmTxt,
	    cancelButtonClass: "gg_cancel_button",
	    confirmButtonClass: "gg_submit_button",
	    customClass: "gg_confirm",
	    showClose:false,
	    callback: (action) => {
	      if (action == "confirm") {
	        callback(action);
	      }
	    },
	  });
	},
	handleDeleteTemperature(id){
		let messageObj = {
		  title: "Delete temperature?",
		  desc: "Once deleted, you will not be able to recover the data.",
		  cancelTxt: "Cancel",
		  confirmTxt: "Delete",
		};
		let callback = () => {
		  this.delCheckInOutData(id,"temperature");
		};
		this.openMessageBox(messageObj, callback);
	},
	handleDeleteCheckIn(id){
		let messageObj = {
		  title: "Delete check in info?",
		  desc: "Once deleted, you will not be able to recover the data.",
		  cancelTxt: "Cancel",
		  confirmTxt: "Delete",
		};
		let callback = () => {
		  this.delCheckInOutData(id,"checkIn");
		};
		this.openMessageBox(messageObj, callback);
	},
	handleDeleteCheckOut(id){
		let messageObj = {
		  title: "Delete check out info?",
		  desc: "Once deleted, you will not be able to recover the data.",
		  cancelTxt: "Cancel",
		  confirmTxt: "Delete",
		};
		let callback = () => {
		  this.delCheckInOutData(id,"checkOut");
		};
		this.openMessageBox(messageObj, callback);
	},
    delCheckInOutData(id,category) {
		let content = 'Delete success'
		if(category =='checkIn'){
			content = 'Check in info has been deleted'
		}else if(category =='checkOut'){
			content = 'Check out info has been deleted'
		}else if(category =='temperature'){
			content = 'Student temperature has been deleted'
		}
      let params = {
        id,
        operationUser: this.user.userId, // || '3fa7f627dda84f3e914f79863e0eda0b',
      }
      Ajax.post("/attendance/timeLine/delete", {}, {}, params).then(res => {
        this.queryTimelineData()
        this.queryStudentDetail()
        this.snackbarData = { visible: true, content: content };
        this.checkInDialogVisible = false
        this.checkOutDialogVisible = false
        this.temperatureDialogVisible = false
      })
    }
  }
};
</script>
<style lang='less' scoped>
	/deep/ .Temperature {
		>p {
			width: 74px;
			/* font-family: OpenSans-Bold; */
			font-family: 'opensans-semibold';
			font-size: 12px;
			color: #B4B4B4;
		}
		span {
			padding-left: 12px;
			font-size: 12px;
			color: #202124;
			font-family: OpenSans-Bold;
		}
	}
	/deep/ .floats {
		position: relative;
		.errorText {
			font-family: Roboto;
			color: #ea4335;
			position: absolute;
			left: 0;
			bottom: -20px;
			z-index: 100;
			font-size: 12px;
		}
		>span {
			font-family: Roboto;
			color: #202124 !important;
			font-size: 16px !important;
			font-weight: 400 !important;
		}
		p {
			font-size: 12px;
			font-family: Roboto;
		}
		input {
			font-family: Roboto;
			color: #202124 !important;
			font-size: 16px !important;
			border: 0;
			border-radius: 0;
			border-bottom: 1px solid #E0E0E0;
		}
		.time-Item {
			.right {
				span {
					font-family: Roboto;
					color: #202124;
					font-size: 12px !important;
					font-weight: 400 !important;
				}
			}
		}
	}
	/deep/ .floatss {
		>span {
			font-family: Roboto;
			color: #202124 !important;
			font-size: 16px !important;
		}
		/* input {
			font-family: Roboto;
			color: #202124 !important;
			font-size: 16px !important;
			border: 0;
			border-radius: 0;
			border-bottom: 1px solid #E0E0E0;
		} */
		.time-Item {
			.right {
				span {
					font-family: Roboto;
					color: #202124;
					font-size: 16px !important;
				}
			}
		}
	}
	/deep/ .dialog-content-Top {
		margin-bottom: 19px !important;
	}
//@import url(); 引入公共css类
/deep/ .section-info-header-right {
	.title {
		font-family: 'Roboto';
		color: #5f6368;
		font-size: 14px;
	}
}
/deep/ .el-dialog {
	width: 700px !important;
}
/deep/ .el-timeline-item__content {
  cursor: pointer;
  &:hover {
    background-color: #EEEEEE;
  }
}
/deep/ .ggPage-main-left {
  display: none;
}
.timeline-wrap {
  padding: 20px 0px 0px 120px;
  .timeline-time {
    position: absolute;
    left: -100px;
    font-size: 12px;
    color: #202124;
	font-family: 'Roboto';
  }
  .icon-wrap {
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    // padding: 5px;
    // width: 30px;
    // height: 30px;
    // line-height: 30px;
    text-align: center;
    border: 1px solid #000;
    border-radius: 100%;
  }
}

.statusImg{
	display: inline-block;
	    /* padding: 3px; */
	    width: 58px;
		height: 58px;
	    border-radius: 50%;
		/* background-color: #ddd; */
		/* margin-right: 5px; */
}
.imgBox{
	margin: 3px;
}
/deep/.ggPageTitle-left .avatar{
	background-color: white;
}
/deep/.previewImg{
	display: none;
}
/deep/.md-field .md-input, .md-field .md-textarea{
	 font-size: 14px;
 }
 /deep/.md-field label{
 	  font-size: 14px;
 }
 /deep/.el-dialog{
	 width: 585px;
 }
/deep/.edoovo-table-empty{
	padding: 80px 32px;
}
	/deep/.menu_bar_button{
		.iconmore_vert-24px{
			margin-top: 8px;
			display: inline-block;
			overflow:visible;
		}
	}
/deep/.menu_bar {
	  margin-top: 12px;
	  width: 40px;
	  height: 40px;
	  line-height: inherit;
	  vertical-align: -0.65rem;
	  .drop_down_menus {
	    padding: 0;
	  }
	  .iconmore_vert-24px{
		  font-size: 24px;
		  line-height: 40px;
		  margin-top: 0;
	  }
	}

</style>
<template>
	<gg-Dialog :visible.sync="isShow" @open="onOpenDialog" :before-close="
	    () => {
	      $emit('cancel');
	    }
	  "
	  width="580px"
	  >
		<div slot="title">Export attendance</div>
		<div class="Between">Between</div>
		<div class="dialog-content">
			<div class="drop-box">
				<div class="area">
					<div class="line clearfix">
						<md-datepicker
						 class="datepicker" v-model="expostData.startTime"
							md-immediately
							:md-disabled-dates="(date) =>{
							  if(!expostData.endTime) {
							    return false
							  }
							  return date.getTime()>expostData.endTime.getTime() || date.getTime()<moment(expostData.endTime).subtract(1,'month').valueOf()
							}"
							>
							<label>From date *</label>
						</md-datepicker>
						<p style="padding-top: 30px;font-size: 16px;color: #202124;">to</p>
						<md-datepicker class="datepicker" v-model="expostData.endTime" md-immediately :error="errorObj"

						:md-disabled-dates="(date) =>{
						  if(!expostData.startTime) {
						    return false
						  }
						  return date.getTime()<expostData.startTime.getTime() || date.getTime()>moment(expostData.startTime).add(1,'month').valueOf()
						}"
						>
							<label>To date *</label>
						</md-datepicker>
					</div>
				</div>
			</div>
			<div class="typeClass" style="margin: 20px 0;width: 100%;display: flex;">
				<baseGGSelect v-model="expostData.type" placeholder="Format *">
					<md-option :value="`${key+1}`" v-for="(item, key) in typeList" :key="key">{{ item }}</md-option>
				</baseGGSelect>
				<baseGGSelect v-model="expostData.ReportType" placeholder="Report type *">
					<md-option :value="`${key+1}`" v-for="(item, key) in ReportType" :key="key">{{ item }}</md-option>
				</baseGGSelect>
			</div>
			<p class="text-reminder">* indicates a required field</p>

		</div>
		<span slot="footer" class="dialog-footer">
			<gg-cancel-button @click="$emit('cancel')">Cancel</gg-cancel-button>
			<gg-submit-button @click="onSave" :disabled="
		        !expostData.startTime ||
		        !expostData.endTime ||
		        !expostData.type ||
				!expostData.ReportType
		      ">Export</gg-submit-button>
		</span>
		<Snackbar
		  :visible.sync="snackbarData.visible"
		  :content="snackbarData.content"
		/>
	</gg-Dialog>
</template>

<script>
	import { Ajax, Utils } from "@/common";
	import moment from 'moment';
	export default {
		props: {
			dialogVisible: {
				type: Boolean,
				default: false,
			},
			classId: {
				type: String,
				default: ""
			},
			schoolId: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				snackbarData: {
				  visible: false,
				  content: "",
				},
				moment,
				isShow: this.dialogVisible,
				expostData: {
					startTime: null,
					endTime: null,
					type: '',
					ReportType: '',
				},
				typeList: ['XLSX', 'CSV'],
				ReportType: ['Attendance report', 'Check-in check-out report'],
				errorObj: {
					show: true,
					message: 'StudentID already exists',
				},
				typeArr: ["2007", "csv"],
				interval:0
			}
		},
		created() {
			this.$material.locale.dateFormat = "dd/MM/yyyy";
			this.interval = moment(moment()).diff(moment.utc(moment()).format('YYYY-MM-DD hh:mm:ss A'), 'hours')
		},
		watch: {
			dialogVisible(val) {
				this.isShow = val;
			},
		},
		methods: {
			handleSnack(content) {
			  this.snackbarData.content = content;
			  this.snackbarData.visible = true;
			  setTimeout(()=>{
				  this.snackbarData.visible = false;
			  },1500)
			},
			onOpenDialog() {
			},
			onSave() {

				this.handleSnack("Student table has been exported");
				this.$emit('cancel')
				setTimeout(()=>{
					this.expostData={
							startTime: null,
							endTime: null,
							type: '',
							ReportType: '',
						}
				},100)
				let url
				if (this.expostData.ReportType == 1) {
					url = `/attendance/checkInOut/exportReportForAttendanceV1`
				} else {
					url = `/attendance/checkInOut/exportReportForCheckV1`
				}

				if(this.classId){
          window.location = location.protocol+'//' + location.host + `${url}?classId=${this.classId}&interval=${this.interval}&schoolId=${this.schoolId}&endDate=${moment(this.expostData.endTime).utc().format('YYYY-MM-DD HH:mm:ss')}&reportFormat=${this.typeArr[this.expostData.type-1]}
				&startDate=${moment(this.expostData.startTime).utc().format('YYYY-MM-DD HH:mm:ss')}`
				}else{
					window.location =location.protocol+'//' + location.host  + `${url}?endDate=${moment(this.expostData.endTime).utc().format('YYYY-MM-DD HH:mm:ss')}&interval=${this.interval}&reportFormat=${this.typeArr[this.expostData.type-1]}
					&schoolId=${this.schoolId}&startDate=${moment(this.expostData.startTime).utc().format('YYYY-MM-DD HH:mm:ss')}`
				}

				return false
				Ajax.get(
					url, {
						"classId": this.classId,
						"endDate": moment(this.expostData.endTime).format('YYYY-MM-DD HH:mm:ss') ,
						"reportFormat": this.typeArr[this.expostData.type-1],
						"schoolId": this.schoolId,
						"startDate": moment(this.expostData.startTime).format('YYYY-MM-DD HH:mm:ss')
					}
				).then((res) => {
					console.log(res)
					this.isShow = false
					this.expostData={
							startTime: null,
							endTime: null,
							type: '',
							ReportType: '',
						}
					this.handleSnack("Student table has been exported");
					const url = window.URL.createObjectURL(new Blob([res]));
					const link = document.createElement('a');
					link.href = url;
					if(this.expostData.type==1){
						link.setAttribute('download',new Date().getTime()+'.xlsx');
					}else{
						link.setAttribute('download',new Date().getTime()+'.csv');
					}

					document.body.appendChild(link);
					link.click();


				});
			},
		}
	}
</script>

<style lang="less" scoped>
	.typeClass {
		justify-content: space-between;

		/deep/ .md-field {
			width: 260px;
		}
	}

	.clearfix {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.drop-box {
		width: 100%;
		// position: absolute;
		// left: 56px;
		// top: 46px;
		// background: #fff;
		// border-radius: 8px;
		// width: 724px;
		// box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
		// font-size: 16px;
		// color: #202124;
		// line-height: 20px;

		.area {
			width: 100%;
			// padding: 10px 24px 0;
		}

		.hr {
			height: 0;
			border-bottom: 1px solid #E0E0E0;
		}

		.clearfix {
			justify-content: space-between !important;

			&::after {
				width: 0;
				height: 0;
				position: absolute;
				display: inline-block;
			}
		}

		.line {
			position: relative;
			min-height: 48px;
			justify-content: space-between;
			// padding-left: 154px;

			.tit {
				position: absolute;
				left: 0;
				top: 9px;
				line-height: 48px;
				color: #202124;
				font-size: 14px;
				font-weight: 500;
			}

			.md-field {
				margin-bottom: 0;
				font-weight: 500;
				font-size: 16px;

				label {
					color: #B4B4B4;
					left: 0 !important;
				}

				/deep/ .md-input-action {
					width: 50px;
				}
			}

			.datepickerspan {
				float: left;
				line-height: 68px;
				margin: 0 15px;
			}

			&:last-child {
				.tit {
					top: 13px;
				}
			}
		}

		/deep/.datepicker {
			// float: left;
			width: 240px;

			&:last-child {
				width: 240px;
			}
			.md-icon-image {
				position: absolute;
				right: 0;
				margin: 2px auto 0;
			}
			// /deep/ .md-date-icon {
			// 	width: 0;
			// 	left: 0;
			// 	display: none;
			// }

			// /deep/ .md-input {
			// 	padding-right: 0;
			// 	margin-left: 0;
			// }
		}

		.btns {
			text-align: right;
			padding: 10px 20px;

			.el-button {
				margin-left: 30px;

				&.cancel {
					color: #5F6368;
				}
			}
		}
	}

	.Between {
		font-size: 12px;
		color: #202124;
		font-family: 'Roboto';
	}

	.dialog-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		// padding-top: 10px;
		// max-height: 444px;
		// overflow: auto;

		.text-reminder {
			// margin-top: 20px;
			font-size: 12px;
			font-family: Roboto;
			color: #b4b4b4;
		}

		.dialog-content-Top {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			margin-bottom: 20px;
			justify-content: space-between;
		}

		.float {
			width: calc(50% - 10px);
			flex-basis: calc(50% - 10px);
			flex-grow: 0;
			flex-shrink: 0;
		}

		.floats {
			width: calc(50% - 10px);

			/* width: calc(50% - 10px);
	  	flex-basis: calc(50% - 10px);
	  	flex-grow: 0;
	  	flex-shrink: 0; */
			.time-Item {
				display: flex;

				.left {
					display: flex;

					.borders {
						width: 40px;
						color: #000;
						font-size: 16px;
						padding-left: 6px;
						border-bottom: 1px solid #ebebeb;
					}

					/deep/ .el-input {
						height: 30px;
						width: 90px;

						input {
							height: 100%;
						}
					}
				}

				.right {
					width: 100px;
					display: flex;
					margin-left: 20px;
					border-radius: 4px;
					overflow: hidden;
					border: 1px solid #e0e0e0;

					span {
						width: 50px;
						height: 30px;
						display: flex;
						font-size: 12px;
						cursor: pointer;
						align-items: center;
						justify-content: center;
					}

					.blue {
						background-color: #1a73e8;
						color: #FFFFFF;
						border-radius: 4px 0 0 4px;
					}

					.blues {
						background-color: #1a73e8;
						color: #FFFFFF;
						border-radius: 0 4px 4px 0;
					}
				}
			}
		}
	}

	.el-icon {
		cursor: pointer;
		margin-left: 10px;
	}

	/deep/.table-cell-img {
		margin-right: 0px;
	}

	/deep/.el-upload-list.el-upload-list--text {
		display: none;
	}

	/deep/.md-subheader.md-theme-default {
		margin-bottom: 5px;
		min-height: 25px;
		font-size: 12px;
		color: #000;
		font-weight: bold;
		border-bottom: 1px solid #707070;
	}

	/deep/.iconfont {
		margin-right: 5px;
		vertical-align: -0.1em;
	}

	.addImage {
		background-color: #1A73E8;

	}

	.addImage:hover {
		background-color: #2B7DE9;
	}

	.is-disabled {
		background-color: #a0cfff;
	}

	.addImage:hover {
		background-color: #2B7DE9;
	}

	.is-disabled:hover {
		background-color: #a0cfff;
	}

	/deep/.textPhoto {
		display: block;
	}

	/deep/.clearfix .md-ripple {
		display: none;
	}
	/deep/.md-input-action{
		display: none;
	}
	/deep/.el-dialog__body{
		padding: 30px 20px;
	}
	/deep/.md-input{
		margin-left: 0!important;
	}
	/deep/.el-dialog{
		top: 50%;
		transform: translate(0%, -50%);
		margin-top: 0!important;
	}
</style>

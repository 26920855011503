<!-- commbook -->
<template>
	<gg-Dialog :visible.sync="isShow" ref="resetPassword" @open="onOpenDialog" @close='CloseDialog' :before-close="() => {$emit('cancel')}">
		<div slot="title">
			{{ isEditable ? 'Edit' : 'Add' }} temperature for
			<head-img :firstName="firstName" :lastName="lastName" class="table-cell-img" :src="photo"
				line-height="20" />
			{{studentName}}
			<span style="float: right;" v-if="!isEditModel">
				<i class="el-icon el-icon-edit" v-if="
		    $buttonPermission('Attendance', 'Full') ||
		    $buttonPermission('Attendance', 'Edit')
		  " @click="isEditModel = true"></i>
				<i class="el-icon el-icon-delete" v-if="
		    $buttonPermission('Attendance', 'Full')
		  " @click="$emit('delete', chooseCheckInOutItem.id)"></i>
				<i class="el-icon el-icon-close" @click="$emit('cancel')"></i>
			</span>
		</div>
		<div class="dialog-content">
			<!-- <div class="float">
        <p style="padding-bottom: 10px; color: #B4B4B4;">Time</p>
        <span>{{ DateNow }}</span>
      </div> -->
			<div class="dialog-content-Top">
				<div class="floats">
					<p style="padding-bottom: 10px; color: #B4B4B4;">Date</p>
					<span style="font-weight: 600;color: #000;">{{dateS}}</span>
				</div>
				<div class="floats">
					<p style="padding-bottom: 10px; color: #B4B4B4;" v-if="timeStateShow">Time</p>
					<p style="padding-bottom: 10px; color: #ea4335;" v-else>Time</p>
					<!-- 错误提示 -->
					<div class="errorText" v-if="!timeStateShow">Invalid time</div>
					<!-- <span>{{ DateNow }}</span> -->
					<div class="time-Item">
						<div class="left">
							
							<el-input :maxlength='2' @input="inputHH" v-model="timeData.hh" placeholder=""></el-input>
							<span style="margin: 0 10px;font-size: 16px;color:#202124;font-family: 'Roboto';">:</span>
							<el-input :maxlength='2' @input="inputmm" v-model="timeData.mm" placeholder=""></el-input>
							
						</div>
						<div class="right">
							<span :class=" timeData.state == 'AM' ? 'blue' : '' " @click="stateBtnS('AM')">AM</span>
							<span :class=" timeData.state == 'PM' ? 'blues' : '' "  @click="stateBtnS('PM')">PM</span>
						</div>
					</div>
				</div>
			</div>
			<p style="padding-bottom: 11px; color: #1a73e8;">Temperature *</p>
			<temperatureSelect ref="temperatureSelects" :stateShows='stateShows1' v-if="isEditModel" class="float" @save="onSaveTemp" @stateBtns='stateBtn' :value="tempValue" />
			<!-- <div class="float" v-else>
				<p style="padding-bottom: 10px; color: #B4B4B4;">Temperature</p>
				<span>{{ tempValue }}°C</span>
			</div> -->
			<div style="height: 10px;"></div>
			<baseGGSelect
			:chips='true'
			  v-if="isEditModel"
			  v-model="paramsDetail.symptomsList"
			  placeholder="Health check"
			  multiple
			>
			  <md-optgroup label="Illness" v-if="illnessHealthCheckList.length != 0">
			    <md-option :value="item.value" v-for="(item, key) in illnessHealthCheckList" :key="key">{{ item.value }}</md-option>
			  </md-optgroup>
			  <md-optgroup label="Visual Health Check" v-if="visualHealthCheckList.length != 0">
			    <md-option :value="item.value" v-for="(item, key) in visualHealthCheckList" :key="key">{{ item.value }}</md-option>
			  </md-optgroup>
			</baseGGSelect>
			<div v-else style="width: 100%; padding-top: 20px">
			  <p style="padding-bottom: 10px; color: #b4b4b4">Health check</p>
			  <p style="min-width: 70px; text-align: center; float: left; margin-right: 8px; padding: 6px 8px; border-radius: 20px; border: 1px solid #E0E0E0;" 
			    v-for="(item, key) in paramsDetail.symptomsList"
			    :key="key"
			  >
			    {{ item }}
			  </p>
			  <!-- <span>{{
			    paramsDetail.symptomsList && paramsDetail.symptomsList.join(",")
			  }}</span> -->
			</div>
			<gg-input
			  v-model="paramsDetail.remarks"
			  placeholder="Remarks"
			/>
			<p class="text-reminder">* indicates a required field</p>
		</div>
		
		<span slot="footer" class="dialog-footer" v-if="isEditModel">
			<gg-cancel-button @click="$emit('cancel')">Cancel</gg-cancel-button>
			<gg-submit-button @click="$emit('save', {tempValue,paramsDetail,timeData})" :disabled="!isEditModel || !tempValue || !timeStateShow">
				{{isEditable ? 'Save' : 'Add'}}</gg-submit-button>
		</span>
	</gg-Dialog>
</template>

<script>
	import {
		Utils
	} from "@/common";
	import temperatureSelect from '../modules/new-temperature-select.vue';
	import moment from "moment";
	import { Ajax } from "@/common";
	import { mapState } from "vuex";
	export default {
		components: {
			temperatureSelect
		},
		props: {
			isEditable: {
				type: Boolean,
				default: false
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
			checkInChoutId: {
				type: String,
				default: ''
			},
			photo: {
				type: String,
				default: "",
			},
			studentName: {
				type: String,
				default: "",
			},
			chooseCheckInOutItem: {
				type: Object,
				default: () => {
					return {}
				}
			},
			isEdit: {
				type: Boolean,
				default: true,
			},
			lastName: {
				type: String,
				default: "",
			},
			firstName: {
				type: String,
				default: "",
			},
			editTempValue: {
				type: String,
				default: ''
			},
			studentId: {
				type: String,
				default: ''
			},
			
			id: {
				type: String,
				default: ''
			},
			addEdit: {
				type: Number,
				default: 2
			},
			checkInDate: {
			 type: String,
			 default: "",
			},
		},
		data() {
			return {
				tempValue: '',
				isShow: this.dialogVisible,
				isEditModel: true,
				DateNow: '',
				dateS: moment().format("DD/MM/YYYY"),
				paramsDetail: {
				  remarks: "",
				  symptomsList: [],
				},
				timeData: {
					hh: moment().format("h"),
					mm: moment().format("mm"),
					state: moment().format("A"),
					states: true,
				},
				stateShows1: false,
				states: 'In class',
				illnessHealthCheckList: [],
				visualHealthCheckList: [],
				timeStateShow: true
			};
		},
		computed: {
		  ...mapState({
		    user: (state) => state.user,
		  }),
		},
		watch: {
			
			dialogVisible(val) {
				this.isShow = val;
			},
			'timeData.hh': {
				handler(e) {
					this.$emit('editTime',this.timeData)
				}
			},
			'timeData.mm': {
				handler(e) {
					this.$emit('editTime',this.timeData)
				}
			},
			'timeData.state': {
				handler(e) {
					this.$emit('editTime',this.timeData)
				}
			},
			'timeData.states': {
				handler(e) {
					this.$emit('editTime',this.timeData)
				}
			},
		},
		created() {
			
		},
		methods: {
			inputHH () {
					this.timeData.hh=this.timeData.hh.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')
					if (this.timeData.hh > 12 || this.timeData.mm > 60 || this.timeData.hh == '' || this.timeData.mm == '') {
						this.timeStateShow = false
					} else {
						this.timeStateShow = true
					}
			},
			inputmm () {
					  this.timeData.mm=this.timeData.mm.replace(/[^\d]/g,'').replace(/^-1{1,}/g,'')
					  if (this.timeData.hh > 12 || this.timeData.mm > 60 || this.timeData.hh == '' || this.timeData.mm == '') {
					  	this.timeStateShow = false
					  } else {
					  	this.timeStateShow = true
					  }
			},
			queryHealthCheckList() {
			  Ajax.post(`/usermanage/setting/getList`, {
			    dataLevel: "healthCheck",
			    dataType: "illness",
			    userId: this.user.userId,
			  }).then((res) => {
			    this.illnessHealthCheckList = res.data || []
			  });
			  Ajax.post(`/usermanage/setting/getList`, {
			    dataLevel: "healthCheck",
			    dataType: "visualCheck",
			    userId: this.user.userId,
			  }).then((res) => {
			    this.visualHealthCheckList = res.data || []
			  });
			  Ajax.get(`/attendance/timeLine/getInclassV1?checkInOutId=${this.id}`, {}).then((res) => {
				this.$refs.temperatureSelects.stateShow = res.data
				this.stateShows1 = res.data
				this.timeData.states = res.data
				// this.$refs.temperatureSelects.stateShows = !res.data
			  });
			},
			onDelete() {

			},
			onSaveTemp(value) {
				this.tempValue = value
			},
			// inclas  adhoc
			stateBtn (e) {
				this.timeData.states = e
			},
			// AMPM
			stateBtnS(e) {
				this.timeData.state = e
			},
			CloseDialog () {
				// this.timeData = {
				// 	hh: moment().format("h"),
				// 	mm: moment().format("mm"),
				// 	state: moment().format("A")
				// }
			},
			onOpenDialog() {
				console.log(this.addEdit,'123')
				// if (Object.keys(this.chooseCheckInOutItem).length && this.isEditable) {
				// 	const { symptomsList,temperatrue,remarks,checkTime } = this.chooseCheckInOutItem
				// 	this.paramsDetail.symptomsList = symptomsList
				// 	this.tempValue = temperatrue
				// 	this.paramsDetail.remarks = remarks
				// 	this.timeData.hh = checkTime.split(' ')[1].split(':')[0]
				// 	this.timeData.mm = checkTime.split(' ')[1].split(':')[1]
				// 	this.timeData.state = checkTime.split(' ')[2]
				// }
				this.queryHealthCheckList();
				this.dateS = moment(this.checkInDate,['YYYY-MM-DD']).format('DD/MM/YYYY')
				// this.DateNow = Utils.getDateNowByAmPm()
				this.DateNow = moment().format("hh:mm A")
				if (this.isEditable) {
					// this.isEditModel = false
					// console.log(this.chooseCheckInOutItem.checkTime)
					// this.DateNow = moment.utc(this.chooseCheckInOutItem.createTime, ["YYYY-MM-DD hh:mm:ss"]).format(
					// 	"hh:mm A")

					// this.tempValue = this.editTempValue
				} else {
					this.timeData = {
						hh: moment().format("h"),
						mm: moment().format("mm"),
						state: moment().format("A"),
						states: true,
					}
					this.tempValue = ''
					this.paramsDetail = {
					  remarks: "",
					  symptomsList: [],
					}
				}
				// console.log(this.isEditable, this.isEditModel)
			}
			// onCancel() {
			//   this.$emit("onCancel");
			// }
		},
		created() {},
	};
</script>
<style lang='less' scoped>
	&::-webkit-inner-spin-button{
	   // webkit内核input[number]默认箭头样式
	  -webkit-appearance: none !important;
	   margin: 0;
	   -moz-appearance: textfield;
	}
	//@import url(); 引入公共css类
	.dialog-content {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 20px;
		/* max-height: 444px; */
		overflow: auto;

		.dialog-content-Top {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;
			justify-content: space-between;
		}

		.text-reminder {
			margin-top: 20px;
			font-size: 12px;
			font-family: Roboto;
			color: #b4b4b4;
		}

		.floats {
			flex: 1;

			/* width: calc(50% - 10px);
			flex-basis: calc(50% - 10px);
			flex-grow: 0;
			flex-shrink: 0; */
			.time-Item {
				display: flex;

				.left {
					display: flex;
					.borders {
						width: 40px;
						color: #000;
						font-size: 16px;
						padding-left: 6px;
						border-bottom: 1px solid #ebebeb;
					}
					/deep/ .el-input {
						height:30px;
						width: 90px;
						input {
							height: 100%;
						}
					}
				}

				.right {
					width: 100px;
					display: flex;
					margin-left: 20px;
					border-radius: 4px;
					overflow: hidden;
					border: 1px solid #e0e0e0;

					span {
						width: 50px;
						height: 30px;
						display: flex;
						font-size: 12px;
						cursor: pointer;
						align-items: center;
						justify-content: center;
					}

					.blue {
						background-color: #1a73e8;
						color: #FFFFFF;
						border-radius: 4px 0 0 4px;
					}

					.blues {
						background-color: #1a73e8;
						color: #FFFFFF;
						border-radius: 0 4px 4px 0;
					}
				}
			}
		}
	}

	.el-icon {
		cursor: pointer;
		margin-left: 10px;
	}

	/deep/.table-cell-img {
		margin-right: 0px;
	}

	/deep/.textPhoto {
		display: block;
	}
 /deep/.chips .md-chip.md-deletable.md-clickable .md-button.md-input-action{
	 width: 20px;
	 height: 20px;
	 margin-left: 8px;
 }
/deep/.chips .md-chip.md-deletable.md-clickable{
	 height: 34px;
	 line-height: 34px;
 }
 /deep/.md-chip.md-clickable>.md-ripple{
	 padding-right: 40px;
 }
 /deep/.md-chip .md-button.md-input-action .md-ripple{
	 background-color: #5f6368;
 }
 /deep/.chips .md-chip.md-deletable.md-clickable .md-button.md-input-action .md-button-content .md-icon{
	 height: 15px;
	 line-height: 15px;
	 min-width: 15px;
	
 }
/deep/.chips .md-chip.md-deletable.md-clickable .md-button.md-input-action .md-button-content{
	 height: 15px;
 }
 /deep/.md-chip.md-theme-default.md-deletable:not(.md-disabled):hover .md-input-action .md-icon svg, .md-chip.md-theme-default.md-clickable:not(.md-disabled):hover .md-input-action .md-icon svg{
	 fill: white;
 }
 /deep/.chips .md-chip.md-deletable.md-clickable{
	  background-color: white;
	 	 border: 1px solid #E0E0E0;
 }
 /deep/.md-chip.md-clickable > .md-ripple{
 		font-size: 14px!important;
		line-height: 32px;
 	}
</style>

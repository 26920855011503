<!-- commbook -->
<template>
	<gg-Dialog :visible.sync="isShow" ref="resetPassword" @open="onOpenDialog" @close='CloseDialog' :before-close="() => {$emit('cancel')}">
		<div slot="title">
			{{ isEditable ? (isEditModel)?'Edit':'' : 'Add' }} {{isEditModel?'temperature':'Temperature'}} for
			<head-img :firstName="firstName" :lastName="lastName" class="table-cell-img" :src="photo"
				line-height="20" />
			{{studentName}}
			<span style="float: right;" v-if="!isEditModel">
				<i class="iconfont iconedit" v-if="
		  ($buttonPermission('Attendance', 'Full') ||
		              $buttonPermission('Attendance', 'Edit'))&&
		  			currentDate==nowTime
		            " @click="isEditModel = true"></i>
				<i class="iconfont icondelete" v-if="
		     $buttonPermission('Attendance', 'Full')
		   " @click="$emit('delete', chooseCheckInOutItem.id)"></i>
				<i class="iconfont iconclose" @click="$emit('cancel')"></i>
			</span>
		</div>
		<div class="dialog-content">
			<!-- <div class="float">
        <p style="padding-bottom: 10px; color: #B4B4B4;">Time</p>
        <span>{{ DateNow }}</span>
      </div> -->
	  <div class="dialog-content-Top"  v-if="isEditModel">
	  	<div class="floats">
	  		<p style="padding-bottom: 10px; color: #B4B4B4;">Date</p>
	  		<span style="font-weight: 600;color: #000;">{{dateS}}</span>
	  	</div>
	  	<div class="floats">
	  		<p style="padding-bottom: 10px; color: #B4B4B4;" v-if="timeStateShow">Time</p>
	  		<p style="padding-bottom: 10px; color: #ea4335;" v-else>Time</p>
	  		<!-- 错误提示 -->
	  		<div class="errorText" v-if="!timeStateShow">Invalid time</div>
	  		<!-- <span>{{ DateNow }}</span> -->
	  		<div class="time-Item">
	  			<div class="left">
	  				
	  				<el-input :maxlength='2' @input="inputHH" v-model="timeData.hh" placeholder=""></el-input>
	  				<span style="margin: 0 10px;font-size: 16px;color:#202124;font-family:Roboto;">:</span>
	  				<el-input :maxlength='2' @input="inputmm" v-model="timeData.mm" placeholder=""></el-input>
	  				
	  			</div>
	  			<div class="right">
	  				<span :class=" timeData.state == 'AM' ? 'blue' : '' " @click="stateBtnS('AM')">AM</span>
	  				<span :class=" timeData.state == 'PM' ? 'blues' : '' "  @click="stateBtnS('PM')">PM</span>
	  			</div>
	  		</div>
	  	</div>
	  </div>
			<div class="dialog-content-Tops" v-else>
				<div class="floatss">
					<p style="padding-bottom: 10px; color: #B4B4B4;">Date</p>
					<span style="font-weight: 400;color: #606266;">{{dateS}}</span>
				</div>
				<div class="floatss">
					<p style="padding-bottom: 10px; color: #B4B4B4;">Time</p>
					<!-- <span>{{ DateNow }}</span> -->
					<span style="font-weight: 400;color: #606266;">{{chooseCheckInOutItem.createTime | editTime}}</span>
				</div>
			</div>
			<p style="padding-bottom: 11px; color: #1a73e8;" v-if="isEditModel">Temperature *</p>
			<p style="padding-bottom: 7px; color: #b4b4b4;" v-else>Temperature *</p>
			<temperatureSelect :stateShows='stateShows1' :editShow='true' style="margin-bottom: 20px;" ref="temperatureSelects" v-if="isEditModel" class="float" @save="onSaveTemp" @stateBtns='stateBtn' :value="tempValue" />
			<div class="Temperature" v-else>
				<p class="gray" style="cursor: initial;">{{ chooseCheckInOutItem.status == 3 ? 'In class' : 'Ad hoc' }}</p>
				<span>{{tempValue}} °C</span>
			</div>
			<!-- <div class="float">
			<p style="padding-bottom: 10px; color: #B4B4B4;">Temperature</p>
			<span>{{ tempValue }}°C</span>
		  </div> -->
		  <baseGGSelect
		    v-if="isEditModel"
		    v-model="paramsDetail.symptomsList"
		    placeholder="Health check"
		    multiple
			:chips='true'
		  >
		    <md-optgroup label="Illness" v-if="illnessHealthCheckList.length">
		      <md-option :value="item.value" v-for="(item, key) in illnessHealthCheckList" :key="key">{{ item.value }}</md-option>
		    </md-optgroup>
		    <md-optgroup label="Visual Health Check" v-if="visualHealthCheckList.length">
		      <md-option :value="item.value" v-for="(item, key) in visualHealthCheckList" :key="key">{{ item.value }}</md-option>
		    </md-optgroup>
		  </baseGGSelect>
		  <div v-else style="width: 100%; padding-top: 20px">
		    <p style="padding-bottom: 10px; color: #b4b4b4">Health check</p>
		    <p style="font-size: 16px;color:#202124;min-width: 70px; text-align: center; float: left; margin-right: 8px; padding: 6px 8px; border-radius: 20px; border: 1px solid #E0E0E0;font-family: 'Roboto';" 
		      v-for="(item, key) in symptomsList"
		      :key="key"
		  		  v-if="symptomsList && symptomsList.length!=0"
		    >
		      {{ item }}
		    </p>
		  		<p v-if='!symptomsList||symptomsList.length==0'>-</p>
		  		<!-- <p >-</p> -->
		    <!-- <span>{{
		      paramsDetail.symptomsList && paramsDetail.symptomsList.join(",")
		    }}</span> -->
		  </div>
		  <gg-input
		    v-if="isEditModel"
		    v-model="paramsDetail.remarks"
		    placeholder="Remarks"
		  />
		  <div v-else style="width: 100%; padding-top: 20px">
		    <p style="padding-bottom: 10px; color: #b4b4b4">Remarks</p>
		    <span style="color:#202124;font-family: 'Roboto';font-size: 16px;">{{ paramsDetail.remarks|| '-' }}</span>
		  </div>
			<p class="text-reminder" v-if="isEditModel">* indicates a required field</p>
		</div>
		<span slot="footer" class="dialog-footer" v-if="isEditModel">
			<gg-cancel-button @click="$emit('cancel')">Cancel</gg-cancel-button>
			<gg-submit-button @click="$emit('save', {tempValue,paramsDetail,timeData})" :disabled="!isEditModel || !tempValue || (!is_changed&&!is_timeDatas&&!is_changeds)  || !timeStateShow">
				{{isEditable ? 'Save' : 'Add'}}</gg-submit-button>
		</span>
	</gg-Dialog>
</template>

<script>
	import {
		Ajax,Utils
	} from "@/common";
	import temperatureSelect from '../modules/new-temperature-select.vue';
	import moment from "moment";
	import { mapState } from "vuex";
	export default {
		components: {
			temperatureSelect
		},
		props: {
			symptomsList: {
				type: Array,
				default: () => {
					return []
				}
			},
			isEditable: {
				type: Boolean,
				default: false
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
			checkInChoutId: {
				type: String,
				default: ''
			},
			photo: {
				type: String,
				default: "",
			},
			studentName: {
				type: String,
				default: "",
			},
			chooseCheckInOutItem: {
				type: Object,
				default: () => {
					return {}
				}
			},
			isEdit: {
				type: Boolean,
				default: true,
			},
			lastName: {
				type: String,
				default: "",
			},
			firstName: {
				type: String,
				default: "",
			},
			checkInDate: {
			 type: String,
			 default: "",
			},
			editTempValue: {
				type: String,
				default: ''
			}
		},
		computed: {
		  ...mapState({
		    user: (state) => state.user,
		  }),
		},
		data() {
			return {
				tempValue: '',
				dateS: moment().format("DD/MM/YYYY"),
				timeData: {
					hh: moment().format("h"),
					mm: moment().format("mm"),
					state: moment().format("A"),
					states: true
				},
				timeDatas: {
					hh: '',
					mm: '',
					state: '',
					states: true
				},
				paramsDetail: {
				  remarks: "",
				  symptomsList: [],
				},
				isShow: this.dialogVisible,
				isEditModel: false,
				DateNow: '',
				is_changed: false,
				is_timeDatas: false,
				is_changeds: false,
				firstDatas: {
					remarks: "",
					symptomsList: [],
				},
				stateShows1: false,
				firstData: '',
				illnessHealthCheckList: [],
				visualHealthCheckList: [],
				currentDate: Utils.formatTime(Date.now(), '{y}-{m}-{d}'),
				nowTime: Utils.formatTime(Date.now(), '{y}-{m}-{d}'),
				timeStateShow: true
			};
		},
		watch: {
			dialogVisible(val) {
				this.isShow = val;
				let a
				if (sessionStorage.getItem('date')) {
					a = Utils.formatTime(sessionStorage.getItem('date'), '{y}-{m}-{d}')
				} else {
					a = Utils.formatTime(Date.now(), '{y}-{m}-{d}')
				}
				// this.currentDate = a
			},
			tempValue: {
				handler(res) {
					if (res != this.firstData) {
						this.is_changed = true
					}
					console.log('changed', this.is_changed)
				},
				deep: true
			},
			paramsDetail:{
				handler(res){
					for(let key in res){
						if(res[key]!=this.firstDatas[key]){
							this.is_changeds=true
						}
					}
					console.log('changed',this.is_changeds)
				},deep:true
			},
			timeData:{
				handler(res){
					for(let key in res){
						if(res[key]!=this.timeDatas[key]){
							this.is_timeDatas=true
						}
						console.log('is_timeDatas',this.is_timeDatas)
					}
				},deep:true
			}
		},
		created() {},
		filters: {
			editTime(e) {
				if (e) {
					return moment.utc(e,["YYYY-MM-DD hh:mm:ss"]).local().format("h:mm A")
				}
				// return `${e.split(' ')[1]} ${e.split(' ')[2]}`
			},
		},
		methods: {
			inputHH () {
					this.timeData.hh=this.timeData.hh.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')
					if (this.timeData.hh > 12 || this.timeData.mm > 60 || this.timeData.hh == '' || this.timeData.mm == '') {
						this.timeStateShow = false
					} else {
						this.timeStateShow = true
					}
			},
			inputmm () {
					  this.timeData.mm=this.timeData.mm.replace(/[^\d]/g,'').replace(/^-1{1,}/g,'')
					  if (this.timeData.hh > 12 || this.timeData.mm > 60 || this.timeData.hh == '' || this.timeData.mm == '') {
					  	this.timeStateShow = false
					  } else {
					  	this.timeStateShow = true
					  }
			},
			stateBtnS(e) {
				this.timeData.state = e
			},
			stateBtn (e) {
				
				this.timeData.states = e
			},
			onDelete() {

			},
			onSaveTemp(value) {
				this.tempValue = value
			},
			queryHealthCheckList() {
			  Ajax.post(`/usermanage/setting/getList`, {
			    dataLevel: "healthCheck",
			    dataType: "illness",
			    userId: this.user.userId,
			  }).then((res) => {
			    this.illnessHealthCheckList = res.data || []
			  });
			  Ajax.post(`/usermanage/setting/getList`, {
			    dataLevel: "healthCheck",
			    dataType: "visualCheck",
			    userId: this.user.userId,
			  }).then((res) => {
			    this.visualHealthCheckList = res.data || []
			  });
			},
			CloseDialog () {
				// this.timeData = {
				// 	hh: moment().format("h"),
				// 	mm: moment().format("mm"),
				// 	state: moment().format("A")
				// }
			},
			onOpenDialog() {
				this.queryHealthCheckList();
				this.dateS = moment(this.checkInDate,['YYYY-MM-DD']).format('DD/MM/YYYY')
				// this.DateNow = Utils.getDateNowByAmPm()
				this.DateNow = moment().format("hh:mm A")
				if (this.isEditable) {
					const { createTime } = this.chooseCheckInOutItem
					let time = moment.utc(createTime,["YYYY-MM-DD hh:mm:ss"]).local().format("hh:mm A")
					this.timeData.hh = time.split(' ')[0].split(':')[0]
					this.timeData.hh = Number(this.timeData.hh)
					this.timeData.mm = time.split(' ')[0].split(':')[1]
					this.timeData.state = time.split(' ')[1]
					this.isEditModel = false
					this.stateShows1 = this.chooseCheckInOutItem.status == 3 ? true : false
					this.timeData.states = this.chooseCheckInOutItem.status == 3 ? true : false
					this.paramsDetail.symptomsList =
					  this.chooseCheckInOutItem.symptomsList || [];
					  this.paramsDetail.remarks = this.chooseCheckInOutItem.remarks;
					this.DateNow = moment.utc(this.chooseCheckInOutItem.createTime, ["YYYY-MM-DD hh:mm:ss"]).local()
						.format("hh:mm A")

					this.tempValue = this.editTempValue
					this.firstData = this.editTempValue
					Object.assign(this.firstDatas,this.paramsDetail)
					Object.assign(this.timeDatas,this.timeData)
					this.is_timeDatas=false
					this.is_changed = false
					this.is_changeds = false
				}
				// console.log(this.isEditable, this.isEditModel)
			}
			// onCancel() {
			//   this.$emit("onCancel");
			// }
		},
		created() {},
	};
</script>
<style lang='less' scoped>
	//@import url(); 引入公共css类
	.dialog-content {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 20px;
		/* max-height: 444px; */
		overflow: auto;

		.dialog-content-Top {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;
			justify-content: space-between;
		}
		.dialog-content-Tops {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			margin-bottom: 25px;
			justify-content: space-between;
		}
		.floatss {
			  flex: 1;
		}

		.text-reminder {
			margin-top: 20px;
			font-size: 12px;
			font-family: Roboto;
			color: #b4b4b4;
		}

		.floats {
			flex: 1;

			/* width: calc(50% - 10px);
			flex-basis: calc(50% - 10px);
			flex-grow: 0;
			flex-shrink: 0; */
			.time-Item {
				display: flex;

				.left {
					display: flex;

					.borders {
						width: 40px;
						color: #000;
						font-size: 16px;
						padding-left: 6px;
						border-bottom: 1px solid #ebebeb;
					}

					/deep/ .el-input {
						height: 30px;
						width: 90px;

						input {
							height: 100%;
						}
					}
				}

				.right {
					width: 100px;
					display: flex;
					margin-left: 20px;
					border-radius: 4px;
					overflow: hidden;
					border: 1px solid #e0e0e0;

					span {
						width: 50px;
						height: 30px;
						display: flex;
						font-size: 12px;
						cursor: pointer;
						align-items: center;
						justify-content: center;
					}

					.blue {
						background-color: #1a73e8;
						color: #FFFFFF;
						border-radius: 4px 0 0 4px;
					}

					.blues {
						background-color: #1a73e8;
						color: #FFFFFF;
						border-radius: 0 4px 4px 0;
					}
				}
			}
		}
	}
	.Temperature {
		width: 220px;
		border: 1px solid #eeeeee;
		border-radius: 4px;
		display: flex;
		height: 30px;
		box-sizing: border-box;
		align-items: center;
	
		p {
			width: 60px;
			height: 100%;
			display: flex;
			border-right: 1px solid #e0e0e0;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	
		.blue {
			background-color: #1a73e8;
			color: #FFFFFF;
			border-radius: 4px 0 0 4px;
		}
		.gray {
			background-color: #eeeeee;
			color: #000;
			border-radius: 4px 0 0 4px;
		}
	
		.blues {
			background-color: #1a73e8;
			color: #FFFFFF;
			border-radius: 0 4px 4px 0;
		}
		span {
			height: 100%;
			flex: 1;
			color: #000;
			padding-left: 10px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
		}
	}
	.el-icon {
		cursor: pointer;
		margin-left: 10px;
	}

	/deep/.table-cell-img {
		margin-right: 0px;
	}

	.iconedit,
	.iconclose,
	.icondelete {
		cursor: pointer;
		margin-right: 8px;
		vertical-align: -0.1em;
		color: #5F6368;
	}

	/deep/.textPhoto {
		display: block;
	}
	/deep/.chips .md-chip.md-deletable.md-clickable .md-button.md-input-action{
		 width: 20px;
		 height: 20px;
		 margin-left: 8px;
	 }
	/deep/.chips .md-chip.md-deletable.md-clickable{
		 height: 34px;
		 line-height: 34px;
	 }
	 /deep/.md-chip.md-clickable>.md-ripple{
		 padding-right: 40px;
	 }
	 /deep/.md-chip .md-button.md-input-action .md-ripple{
		 background-color: #5f6368;
	 }
	 /deep/.chips .md-chip.md-deletable.md-clickable .md-button.md-input-action .md-button-content .md-icon{
		 height: 15px;
		 line-height: 15px;
		 min-width: 15px;
		
	 }
	/deep/.chips .md-chip.md-deletable.md-clickable .md-button.md-input-action .md-button-content{
		 height: 15px;
	 }
	 /deep/.md-chip.md-theme-default.md-deletable:not(.md-disabled):hover .md-input-action .md-icon svg, .md-chip.md-theme-default.md-clickable:not(.md-disabled):hover .md-input-action .md-icon svg{
		 fill: white;
	 }
	 /deep/.chips .md-chip.md-deletable.md-clickable{
		  background-color: white;
		 	 border: 1px solid #E0E0E0;
	 }
	 /deep/.md-chip.md-clickable > .md-ripple{
	 		font-size: 14px!important;
	 	}
		/deep/.md-chip.md-clickable > .md-ripple{
				font-size: 14px!important;
				line-height: 32px;
			}
</style>

<template>
  <div class="image-list-view">
    <ul>
      <li v-for="(item, key) in fileList" :key="key">
        <img @click="preview(item.src,item.fileName)" :src="/\.pdf$/.test(item.fileName) ? pdfDefaultImg : item.src" alt="" style="max-width: 120px;">
        <span style="margin-left: 24px; line-height: 72px;">File name: </span>
        <span style="margin-left: 30px; line-height: 72px;">{{ item.fileName }}</span>
        <p style="float: right; margin-right: 20px; height: 72px; line-height: 72px;" v-if="showClose">
          <i class="el-icon-close" style="font-size: 24px; cursor: pointer;" @click="onDelFileItem(key)"></i>
        </p>
      </li>
    </ul>
	<!-- <gg-view-img v-if="isViewImg" :visible.sync="isViewImg" :src="fileList[0].src" :name="fileList[0].fileName||'Preview'" /> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
	showClose:{
		type:Boolean,
		default:true
	}
  },
  data() {
    return {
      pdfDefaultImg: require('./pdf-default.jpg'),
	  isViewImg:false
    };
  },
  methods: {
	  preview(src,name){
		// window.open(`/manage/testFileView?imgUrl=${src}&name=${name}`);  
		let newUrl = this.$router.resolve({
		          path: `/testFileView?imgUrl=${src}&name=${name}`
		        });
		window.open(newUrl.href, "_blank");
	  },
    onDelFileItem(index){
      this.fileList.splice(index, 1)
    }
  },
  created() {},
  mounted(){}
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
li {
  margin-bottom: 8px;
  height: 72px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  img {
    height: 100%;
    vertical-align: top;
  }
}
</style>
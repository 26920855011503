<!-- commbook -->
<template>
  <gg-Dialog
    :visible.sync="isShow"
    ref="resetPassword"
	@close='CloseDialog'
    @open="onOpenDialog"
    :before-close="
      () => {
        $emit('cancel');
      }
    "
  >
    <div slot="title">
	 Check out for
      <head-img
	  :firstName="firstName"
	  :lastName="lastName"
	   
	   class="table-cell-img" :src="photo" line-height="20" />
		{{studentName}}
      <span style="float: right" v-if="!isEditModel">
        <!-- <i class="iconfont iconedit" style="margin-right: 10px;"></i>
        <i class="iconfont iconyiquxiao" style="margin-right: 10px;" @click="$emit('delete', chooseCheckInOutItem.id)"></i>
        <i class="iconfont iconclear-px" @click="$emit('cancel')"></i> -->
        <i
          class="el-icon el-icon-edit"
          v-if="
            $buttonPermission('Attendance', 'Full') ||
            $buttonPermission('Attendance', 'Edit')
          "
          @click="isEditModel = true"
        ></i>
        <i
          class="el-icon el-icon-delete"
          v-if="$buttonPermission('Attendance', 'Full')"
          @click="$emit('delete', chooseCheckInOutItem.id)"
        ></i>
        <i class="el-icon el-icon-close" @click="$emit('cancel')"></i>
      </span>
    </div>
    <div class="dialog-content">
		<div class="dialog-content-Top">
			<div class="floats">
				<p style="padding-bottom: 10px; color: #B4B4B4;">Date</p>
				<span style="font-weight: 600;color: #000;">{{dateS}}</span>
			</div>
			<div class="floats">
				<p style="padding-bottom: 10px; color: #B4B4B4;" v-if="timeStateShow">Time</p>
				<p style="padding-bottom: 10px; color: #ea4335;" v-else>Time</p>
				<!-- 错误提示 -->
				<div class="errorText" v-if="!timeStateShow">Invalid time</div>
				<!-- <span>{{ DateNow }}</span> -->
				<div class="time-Item">
					<div class="left">
						
						<el-input :maxlength='2' @input="inputHH" v-model="timeData.hh" placeholder=""></el-input>
						<span style="margin: 0 10px;font-size: 16px;color:#202124;font-family: 'Roboto';">:</span>
						<el-input :maxlength='2' @input="inputmm" v-model="timeData.mm" placeholder=""></el-input>
						
					</div>
					<div class="right">
						<span :class=" timeData.state == 'AM' ? 'blue' : '' " @click="stateBtnS('AM')">AM</span>
						<span :class=" timeData.state == 'PM' ? 'blues' : '' "  @click="stateBtnS('PM')">PM</span>
					</div>
				</div>
			</div>
		</div>
      <baseGGSelect
        v-if="isEditModel"
        v-model="paramsDetail.dropOffUser"
        placeholder="Pick-up by * "
        class="float"
      >
        <md-option
          v-for="(item, key) in pickUpUserList"
          :key="key"
          :value="item.id"
        >
          {{ item.firstName + " " +item.lastName  }}
        </md-option>
      </baseGGSelect>
      <div class="float" v-else>
        <p style="padding-bottom: 10px; color: #b4b4b4">Pick-up by *</p>
        <span>{{ dropOffUserName }}</span>
      </div>
      <temperatureSelect
        v-if="isEditModel"
        class="float"
        @save="onSaveTemp"
        :value="paramsDetail.checkValue"
      />
      <div class="float" v-else>
        <p style="padding-bottom: 10px; color: #b4b4b4">Temperature</p>
        <span>{{ paramsDetail.checkValue }}</span>
      </div>
      <div style="width: 100%">
        <el-upload
          style="display: inline-block;"
          :auto-upload="false"
          action=""
          accept="image/jpeg,image/png"
          :show-file-list="false"
        			:disabled="fileList && fileList.length==1"
          :on-change="(file, fileList) => {onUploadFileChange(file, fileList)}"
        >
        		<el-button
        		  v-if="isEditModel"
        		  size="small"
        		  type="primary"
        		  class="addImage"
        				  
        				  :disabled="fileList && fileList.length==1"
        		  >
        		  <span class="icon iconfont iconattach_file-web"></span>
        		  
        		  Add image *</el-button
        		>
        </el-upload>
        <div style="padding-top: 20px">
          <imageListPreview :fileList="fileList" />
        </div>
      </div>
      <p class="text-reminder" v-if="isEditModel">* indicates a required field</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <gg-cancel-button @click="$emit('cancel')">Cancel</gg-cancel-button>
      <gg-submit-button
        @click="onSave"
        :disabled="
          !isEditModel ||
          !fileList.length ||
          !paramsDetail.dropOffUser ||
          !paramsDetail.checkValue  || !timeStateShow
        "
        >{{ isEditable ? "Save" : "Check out" }}</gg-submit-button
      >
    </span>
    <img-cut-modal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
	   :isCut='true'
    />
  </gg-Dialog>
</template>

<script>
import { Ajax } from "@/common";
import moment from "moment";
import temperatureSelect from "../modules/temperature-select.vue";
import imageListPreview from "@/components/private-components/ImageListPreview/index.vue";
export default {
  components: {
    temperatureSelect,
    imageListPreview,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    checkInChoutId: {
      type: String,
      default: "",
    },
    studentId: {
      type: String,
      default: "",
    },
    studentName: {
      type: String,
      default: "",
    },
	isEdit: {
	  type: Boolean,
	  default: true,
	},
	photo: {
	  type: String,
	  default: "",
	},
	checkInDate: {
	 type: String,
	 default: "",
	},
	lastName: {
		 type: String,
		 default: "",
		},
		firstName: {
		 type: String,
		 default: "",
		},
    chooseCheckInOutItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
		timeStateShow: true,
		dateS: moment().format("DD/MM/YYYY"),
		timeData: {
			hh: moment().format("h"),
			mm: moment().format("mm"),
			state: moment().format("A"),
		},
      dropOffUserName: "",
      isEditModel: true,
      isShow: this.dialogVisible,
      isImgCutShow: false,
      fileList: [],
      paramsDetail: {
        dropOffUser: "",
        checkValue: "",
        fileList: [],
      },
      pickUpUserList: [],
    };
  },
  watch: {
    dialogVisible(val) {
      this.isShow = val;
    },
  },
  methods: {
	  inputHH () {
	  		this.timeData.hh=this.timeData.hh.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')
	  		if (this.timeData.hh > 12 || this.timeData.mm > 60 || this.timeData.hh == '' || this.timeData.mm == '') {
	  			this.timeStateShow = false
	  		} else {
	  			this.timeStateShow = true
	  		}
	  },
	  inputmm () {
	  		  this.timeData.mm=this.timeData.mm.replace(/[^\d]/g,'').replace(/^-1{1,}/g,'')
	  		  if (this.timeData.hh > 12 || this.timeData.mm > 60 || this.timeData.hh == '' || this.timeData.mm == '') {
	  		  	this.timeStateShow = false
	  		  } else {
	  		  	this.timeStateShow = true
	  		  }
	  },
	  stateBtnS(e) {
	  	this.timeData.state = e
	  },
	  onUploadFileChange(file, fileList){
	  	 const isLt2M = file.size / 1024 / 1024 < 25     //这里做文件大小限制
	  			   console.log(isLt2M)
	  			  if(!isLt2M) {
	  			  	this.$message({
	  			  		message: 'Upload files should not exceed 25MB',
	  			  		type: 'warning'
	  			  	});
	  			  	return  isLt2M
	  			  }
	    if (file) {
	      let fileName = file.name
	      let formData = new FormData();
	      formData.append("file", file);
	      formData.append("multipartFile", file.raw);
	      Ajax.post(
	        // "/media/public/file/upload",
	        "/media/public/file/uploadExt",
	        formData,
	        {
	          headers: { "Content-Type": "multipart/form-data" },
	        }
	      )
	      .then((res) => {
	        if (res.code === "0000") {
	  				this.is_changed=true
	          let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
	          this.fileList.push({
	            fileName,
	            src: imgSrc,
	            id: res.data.requestId,
	          });
	  		
	        }
	      })
	      .catch((err) => {
	        console.log(err)
	      });
	    }
	  },
    onSave() {
		this.pickUpUserList.forEach((item) => {
		  if (this.paramsDetail.dropOffUser === item.id) {
		  this.paramsDetail.dropUserType=item.type
		  }
		});
      let fileList = [];
      this.fileList.forEach((item) => {
        fileList.push(item.src);
      });
      this.paramsDetail.fileList = fileList;
      this.$emit("save", {paramsDetail:this.paramsDetail,timeData: this.timeData});
    },
    _uploadCallback(formData, fileData) {
      let fileName = fileData.name;
      formData.append("multipartFile", fileData.raw);
      this.isImgCutShow = false;
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          this.fileList.push({
            fileName,
            src: imgSrc,
            id: res.data.requestId,
          });
          // this.isImgCutShow = false;
        })
        .catch(() => {
          // this.isImgCutShow = false;
        });
    },
    onSaveTemp(value) {
      let fileList = [];
      this.fileList.forEach((item) => {
        fileList.push(item.src);
      });
      this.paramsDetail.fileList = fileList;
      this.paramsDetail.checkValue = value;
    },
    queryListContactByStuId() {
      Ajax.get(
        `/usermanage/student/listContactByStuId/${this.studentId}`,
        {}
      ).then((res) => {
        this.pickUpUserList = res.data || [];
        this.pickUpUserList.forEach((item) => {
          if (this.chooseCheckInOutItem.dropOffOrPickUpId === item.id) {
            this.dropOffUserName = item.firstName + " " + item.lastName;
			console.log(this.dropOffUserName)
          }
        });
      });
    },
	CloseDialog () {
		// this.timeData = {
		// 	hh: moment().format("h"),
		// 	mm: moment().format("mm"),
		// 	state: moment().format("A")
		// }
	},
    onOpenDialog() {
      this.queryListContactByStuId();
	  this.dateS = moment(this.checkInDate,['YYYY-MM-DD']).format('DD/MM/YYYY')
	  this.timeData = {
	  	hh: moment().format("h"),
	  	mm: moment().format("mm"),
	  	state: moment().format("A")
	  }
      if (this.isEditable) {
        this.fileList = [];
        this.isEditModel = false;
		this.paramsDetail.id = this.chooseCheckInOutItem.id
        this.paramsDetail.dropOffUser = this.chooseCheckInOutItem.dropOffOrPickUpId;
        this.paramsDetail.checkValue = this.chooseCheckInOutItem.temperatrue;
        this.paramsDetail.fileList = this.chooseCheckInOutItem.fileList || [];
        this.paramsDetail.fileList.forEach((item) => {
          this.fileList.push({
            src: item,
          });
        });
      } else {
		  this.fileList = []
        this.paramsDetail = {
          dropOffUser: "",
          checkValue: "",
          fileList: [],
        };
      }
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.dialog-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  max-height: 444px;
  overflow: auto;
  .dialog-content-Top {
  	display: flex;
  	width: 100%;
  	flex-wrap: wrap;
  	margin-bottom: 20px;
  	justify-content: space-between;
  }
  .text-reminder {
    margin-top: 20px;
    font-size: 12px;
    font-family: Roboto;
    color: #b4b4b4;
  }
  .float {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  /* max-height: 444px; */
  overflow: auto;
  .text-reminder {
    margin-top: 20px;
    font-size: 12px;
    font-family: Roboto;
    color: #b4b4b4;
  }
  .dialog-content-Top {
  	display: flex;
  	width: 100%;
  	flex-wrap: wrap;
  	margin-bottom: 20px;
  	justify-content: space-between;
  }
  .float {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
  .floats {
  	flex: 1;
  
  	/* width: calc(50% - 10px);
  	flex-basis: calc(50% - 10px);
  	flex-grow: 0;
  	flex-shrink: 0; */
  	.time-Item {
  		display: flex;
  
  		.left {
  			display: flex;
  			.borders {
  				width: 40px;
  				color: #000;
  				font-size: 16px;
  				padding-left: 6px;
  				border-bottom: 1px solid #ebebeb;
  			}
  			/deep/ .el-input {
  				height:30px;
  				width: 90px;
  				input {
  					height: 100%;
  				}
  			}
  		}
  
  		.right {
  			width: 100px;
  			display: flex;
  			margin-left: 20px;
  			border-radius: 4px;
  			overflow: hidden;
  			border: 1px solid #e0e0e0;
  
  			span {
  				width: 50px;
  				height: 30px;
  				display: flex;
  				font-size: 12px;
  				cursor: pointer;
  				align-items: center;
  				justify-content: center;
  			}
  
  			.blue {
  				background-color: #1a73e8;
  				color: #FFFFFF;
  				border-radius: 4px 0 0 4px;
  			}
  
  			.blues {
  				background-color: #1a73e8;
  				color: #FFFFFF;
  				border-radius: 0 4px 4px 0;
  			}
  		}
  	}
  }
}
.el-icon {
  cursor: pointer;
  margin-left: 10px;
}
/deep/.table-cell-img {
  margin-right: 0px;
}
/deep/.el-upload-list.el-upload-list--text {
  display: none;
}

 /deep/.iconfont{
	 margin-right:5px;
	 vertical-align:-0.1em;
 }
 .addImage{
 	 background-color: #1A73E8;
 	 
 }
 .addImage:hover{
 	 background-color: #2B7DE9;
 }
 .is-disabled{
 	  background-color: #a0cfff;
  }
 .addImage:hover{
 	 background-color: #2B7DE9;
 }
 .is-disabled:hover{
 	 background-color: #a0cfff;
 }
 /deep/.textPhoto{
 	 display: block;
 }
</style>